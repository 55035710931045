import { createElement } from "react";
import React from "react";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { empty, append, singleton, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { BlockHeader } from "../Components/Text.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { TabsMenu } from "./TabsMenu.js";

export function SectionContainer(props) {
    let elems_3;
    const content = createElement("div", {
        className: join(" ", ["flex", "flex-shrink-0", "flex-col", "mb-3", "bg-white", "rounded", "px-12", "py-9"]),
        children: reactApi.Children.toArray([props.Content]),
    });
    return createElement("div", createObj(ofArray([["className", join(" ", ["mt-8"])], (elems_3 = toList(delay(() => {
        let elems_2, elems_1;
        const matchValue = props.Title;
        return (matchValue == null) ? singleton(content) : singleton(createElement("div", createObj(singleton_1((elems_2 = [createElement("div", createObj(ofArray([(elems_1 = [createElement(BlockHeader, {
            Text: matchValue,
        })], ["children", reactApi.Children.toArray(Array.from(elems_1))]), ["className", join(" ", ["mb-4"])]]))), content], ["children", reactApi.Children.toArray(Array.from(elems_2))])))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function TableContainer(props) {
    let elems_4;
    return createElement("div", createObj(ofArray([["className", join(" ", ["mt-8"])], (elems_4 = toList(delay(() => {
        let elems_2, elems;
        const matchValue = props.Title;
        return (matchValue == null) ? singleton(createElement("div", {
            className: join(" ", ["bg-white"]),
            children: reactApi.Children.toArray([props.Content]),
        })) : singleton(createElement("div", createObj(singleton_1((elems_2 = [createElement("div", createObj(ofArray([(elems = [createElement(BlockHeader, {
            Text: matchValue,
        })], ["children", reactApi.Children.toArray(Array.from(elems))]), ["className", join(" ", ["mb-4"])]]))), createElement("div", {
            className: join(" ", ["bg-white"]),
            children: reactApi.Children.toArray([props.Content]),
        })], ["children", reactApi.Children.toArray(Array.from(elems_2))])))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function TableContainerWithTabs(props) {
    let elems_2;
    const patternInput = reactApi.useState(true);
    const setIsTab1Selected = patternInput[1];
    const isTab1Selected = patternInput[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["mt-8"])], (elems_2 = toList(delay(() => {
        let matchValue;
        return append((matchValue = props.Title, (matchValue == null) ? (empty()) : singleton(createElement(BlockHeader, {
            Text: matchValue,
        }))), delay(() => {
            let elems;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-4"])], (elems = [createElement(TabsMenu, {
                Tabs: ofArray([{
                    IsSelected: isTab1Selected,
                    Label: props.Tab1.Title,
                    OnClick: () => {
                        setIsTab1Selected(true);
                        const matchValue_1 = props.Tab1.OnSelect;
                        if (matchValue_1 == null) {
                        }
                        else {
                            matchValue_1();
                        }
                    },
                    TestId: "",
                }, {
                    IsSelected: !isTab1Selected,
                    Label: props.Tab2.Title,
                    OnClick: () => {
                        setIsTab1Selected(false);
                        const matchValue_2 = props.Tab2.OnSelect;
                        if (matchValue_2 == null) {
                        }
                        else {
                            matchValue_2();
                        }
                    },
                    TestId: "",
                }]),
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                let elems_1;
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "mt-4"])], (elems_1 = toList(delay(() => (isTab1Selected ? singleton(props.Tab1.Content) : singleton(props.Tab2.Content)))), ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

