import { EmptyResponse_$reflection, SetId, DocumentDetail_$reflection, RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { tryFind, contains, filter, append, ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { DataTransfer_CreateOrUpdateSet, DataTransfer_UpdateQrCodesDto_$reflection, DataTransfer_UpdateQrCodesDto, DataTransfer_ProblemReportTool_$reflection, DataTransfer_DetailSet_$reflection, DataTransfer_CreateOrUpdateSet_$reflection } from "../../Shared/Set.js";
import { getAssignableTools, downloadFileCmd, saveImageCmd, deleteImageCmd, getAssignableToolsWithSetId } from "../../Requests/Set.js";
import { UserConfiguration as UserConfiguration_1, QuickOverviewConfiguration as QuickOverviewConfiguration_1, UserConfiguration_$reflection } from "../../Shared/User.js";
import { getCustomerConfiguration } from "../../Requests/Configuration.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { string_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { SetId__get_unwrap } from "../../Shared/Shared.js";
import { getSetNotifications } from "../../Requests/Notification.js";
import { NoticeDto_$reflection, NoticeDto } from "../../Shared/Tool.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { unwrapNoticeId } from "../../Shared/Helper.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { getBlob, onBlobReceived } from "../../Communication.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../CommunicationV2.js";
import { List_distinct } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { stringHash } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { getChangelogs } from "../../Requests/Changelog.js";

function initState(userData) {
    return new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), userData, undefined, false, {
        IsLoading: false,
        IsOpen: false,
    }, new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []));
}

export function initViewSet(setId, userData) {
    let bind$0040;
    return [(bind$0040 = initState(userData), new State(bind$0040.Set, bind$0040.Tools, bind$0040.UserConfiguration, bind$0040.UserData, userData.Customer, bind$0040.ImageViewerOpen, bind$0040.QuickOverviewDialog, bind$0040.Documents, bind$0040.CustomerConfiguration, bind$0040.Storages, bind$0040.BaseSet, bind$0040.ProblemReports, bind$0040.Notifications, bind$0040.Changelogs)), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), DataTransfer_CreateOrUpdateSet_$reflection()));
    })))), `/api/sets/${setId}/basis`, (Item) => (new Msg(20, [Item])), (Item_1) => (new Msg(36, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), DataTransfer_DetailSet_$reflection()));
    })))), `/api/sets/${setId}/details`, (Item_2) => (new Msg(2, [Item_2])), (Item_3) => (new Msg(36, [Item_3]))), getAssignableToolsWithSetId((Item_4) => (new Msg(25, [Item_4])), (Item_5) => (new Msg(36, [Item_5])), setId), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), UserConfiguration_$reflection()));
    })))), "/api/users/configuration", (Item_6) => (new Msg(16, [Item_6])), (Item_7) => (new Msg(36, [Item_7]))), getCustomerConfiguration((Item_8) => (new Msg(26, [Item_8])), (Item_9) => (new Msg(36, [Item_9]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_9), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
        const pr_3 = response_3.arrayBuffer();
        return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_10) => (new Msg(27, [Item_10])), (Item_11) => (new Msg(36, [Item_11]))), Cmd_OfPromise_either((url_12) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_12), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_4) => {
        const pr_4 = response_4.arrayBuffer();
        return pr_4.then((blob_4) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_4)), list_type(DocumentDetail_$reflection())));
    })))), `/api/sets/${SetId__get_unwrap(new SetId(setId))}/documents`, (Item_12) => (new Msg(24, [Item_12])), (Item_13) => (new Msg(36, [Item_13]))), Cmd_OfPromise_either((url_15) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_15), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_5) => {
        const pr_5 = response_5.arrayBuffer();
        return pr_5.then((blob_5) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_5)), list_type(DataTransfer_ProblemReportTool_$reflection())));
    })))), `/api/sets/${SetId__get_unwrap(new SetId(setId))}/problem-reports`, (Item_14) => (new Msg(28, [Item_14])), (Item_15) => (new Msg(36, [Item_15]))), getSetNotifications(new SetId(setId), (Item_16) => (new Msg(32, [Item_16])), (Item_17) => (new Msg(36, [Item_17])))]))];
}

export function update(msg, state) {
    let setId, body_5, setId_4, body_7, setId_5, body_9, setId_6, body_11, setId_7, body_13, userConfiguration_1, bind$0040_1;
    switch (msg.tag) {
        case 1:
            return [new State(state.Set, state.Tools, state.UserConfiguration, state.UserData, state.Customer, state.ImageViewerOpen, {
                IsLoading: state.QuickOverviewDialog.IsLoading,
                IsOpen: !state.QuickOverviewDialog.IsOpen,
            }, state.Documents, state.CustomerConfiguration, state.Storages, state.BaseSet, state.ProblemReports, state.Notifications, state.Changelogs), Cmd_none()];
        case 5: {
            const matchValue_1 = state.Set;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const dto = new NoticeDto("00000000-0000-0000-0000-000000000000", matchValue_1.fields[0].Id, "", msg.fields[0], msg.fields[1]);
                return [state, Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg_1[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                    const pr_1 = response_1.arrayBuffer();
                    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                })))), ["/api/sets/notices", toString(0, Auto_generateBoxedEncoder_437914C6(NoticeDto_$reflection(), undefined, undefined, undefined)(dto))], (Item_2) => (new Msg(6, [Item_2])), (Item_3) => (new Msg(36, [Item_3])))];
            }
        }
        case 6: {
            const response_2 = msg.fields[0];
            switch (response_2) {
                case "updated":
                    return [state, singleton((dispatch_1) => {
                        dispatch_1(new Msg(29, []));
                    })];
                case "notAuthorized":
                    return [state, Cmd_errorToast("set.form.validation_errors.not_authorized")];
                default:
                    return [state, singleton((dispatch) => {
                        dispatch(new Msg(29, []));
                    })];
            }
        }
        case 7: {
            const notice = msg.fields[0];
            const matchValue_2 = state.Set;
            if (matchValue_2.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const dto_1 = new NoticeDto(unwrapNoticeId(notice.Id), "", "", notice.Message, notice.IsVisibleForUser);
                return [state, (setId = (new SetId(parse(matchValue_2.fields[0].Id))), (body_5 = toString(0, Auto_generateBoxedEncoder_437914C6(NoticeDto_$reflection(), undefined, undefined, undefined)(dto_1)), Cmd_OfPromise_either((tupledArg_2) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg_2[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_2[1]])]), "application/json", (response_3) => {
                    const pr_2 = response_3.arrayBuffer();
                    return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), string_type));
                })))), [`/api/sets/${SetId__get_unwrap(setId)}/notices`, body_5], (Item_4) => (new Msg(6, [Item_4])), (Item_5) => (new Msg(36, [Item_5])))))];
            }
        }
        case 9: {
            const matchValue_3 = state.Set;
            if (matchValue_3.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, deleteImageCmd(new SetId(parse(matchValue_3.fields[0].Id)), (Item_8) => (new Msg(10, [Item_8])), (Item_9) => (new Msg(36, [Item_9])))];
            }
        }
        case 10:
            return [state, Cmd_batch(ofArray([(msg.fields[0].tag === 1) ? Cmd_errorToast("general.a_error_occured") : Cmd_successToast("delete_photo_dialog.delete_image_success"), singleton((dispatch_2) => {
                dispatch_2(new Msg(29, []));
            })]))];
        case 8: {
            const file = msg.fields[0];
            const matchValue_4 = state.Set;
            if (matchValue_4.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const formData = new FormData();
                formData.append(file.name, file);
                return [state, saveImageCmd(new SetId(parse(matchValue_4.fields[0].Id)), formData, (Item_11) => (new Msg(11, [Item_11])), (Item_12) => (new Msg(36, [Item_12])))];
            }
        }
        case 11: {
            const response_5 = msg.fields[0];
            switch (response_5) {
                case "failed":
                    return [state, Cmd_errorToast("general.a_error_occured")];
                case "notAuthorized":
                    return [state, Cmd_errorToast("set.form.validation_errors.not_authorized")];
                default:
                    return [state, Cmd_batch(ofArray([Cmd_successToast("general.upload_image_success"), singleton((dispatch_3) => {
                        dispatch_3(new Msg(29, []));
                    })]))];
            }
        }
        case 29: {
            const matchValue_5 = state.Set;
            if (matchValue_5.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const set$_2 = matchValue_5.fields[0];
                return [state, Cmd_batch(ofArray([Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_9), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_6) => {
                    const pr_3 = response_6.arrayBuffer();
                    return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), DataTransfer_DetailSet_$reflection()));
                })))), `/api/sets/${set$_2.Id}/details`, (Item_13) => (new Msg(2, [Item_13])), (Item_14) => (new Msg(36, [Item_14]))), Cmd_OfPromise_either((url_12) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_12), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_7) => {
                    const pr_4 = response_7.arrayBuffer();
                    return pr_4.then((blob_4) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_4)), DataTransfer_CreateOrUpdateSet_$reflection()));
                })))), `/api/sets/${set$_2.Id}/basis`, (Item_15) => (new Msg(20, [Item_15])), (Item_16) => (new Msg(36, [Item_16])))]))];
            }
        }
        case 33:
            return [state, Cmd_batch(singleton(Cmd_ofEffect((_arg) => {
                RouterModule_nav(singleton("/tools"), 1, 1);
            })))];
        case 2:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.Tools, state.UserConfiguration, state.UserData, state.Customer, state.ImageViewerOpen, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, state.Storages, state.BaseSet, state.ProblemReports, state.Notifications, state.Changelogs), singleton((dispatch_4) => {
                dispatch_4(new Msg(31, []));
            })];
        case 12: {
            onBlobReceived(msg.fields[0], msg.fields[1]);
            return [state, Cmd_none()];
        }
        case 15:
            return [new State(state.Set, state.Tools, state.UserConfiguration, state.UserData, state.Customer, state.ImageViewerOpen, {
                IsLoading: false,
                IsOpen: false,
            }, state.Documents, state.CustomerConfiguration, state.Storages, state.BaseSet, state.ProblemReports, state.Notifications, state.Changelogs), Cmd_batch(ofArray([Cmd_OfPromise_either((url_15) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_15), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_9) => {
                const pr_5 = response_9.arrayBuffer();
                return pr_5.then((blob_6) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_6)), UserConfiguration_$reflection()));
            })))), "/api/users/configuration", (Item_17) => (new Msg(16, [Item_17])), (Item_18) => (new Msg(36, [Item_18]))), Cmd_successToast("general.saved_successfully")]))];
        case 16:
            return [new State(state.Set, state.Tools, new RequestedValue$1(1, [msg.fields[0]]), state.UserData, state.Customer, state.ImageViewerOpen, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, state.Storages, state.BaseSet, state.ProblemReports, state.Notifications, state.Changelogs), Cmd_none()];
        case 17: {
            const matchValue_6 = state.Set;
            if (matchValue_6.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_OfPromise_either((url_18) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_18), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_10) => {
                    const pr_6 = response_10.arrayBuffer();
                    return pr_6.then((blob_7) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_7)), list_type(DocumentDetail_$reflection())));
                })))), `/api/sets/${SetId__get_unwrap(new SetId(parse(matchValue_6.fields[0].Id)))}/documents`, (Item_20) => (new Msg(24, [Item_20])), (Item_21) => (new Msg(36, [Item_21])))];
            }
        }
        case 18: {
            const matchValue_7 = state.Set;
            if (matchValue_7.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_batch(ofArray([Cmd_successToast("general.download_started"), downloadFileCmd(new SetId(parse(matchValue_7.fields[0].Id)), msg.fields[0], msg.fields[1], (tupledArg_3) => (new Msg(19, [tupledArg_3[0], tupledArg_3[1]])), (Item_25) => (new Msg(36, [Item_25])))]))];
            }
        }
        case 3: {
            const matchValue_8 = state.BaseSet;
            const matchValue_9 = state.Set;
            let matchResult, dto_2, set$_5;
            if (matchValue_8.tag === 0) {
                matchResult = 1;
            }
            else if (matchValue_9.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                dto_2 = matchValue_8.fields[0];
                set$_5 = matchValue_9.fields[0];
            }
            switch (matchResult) {
                case 0: {
                    const dto_3 = new DataTransfer_UpdateQrCodesDto(singleton(msg.fields[0]));
                    return [state, (setId_4 = (new SetId(parse(set$_5.Id))), (body_7 = toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_UpdateQrCodesDto_$reflection(), undefined, undefined, undefined)(dto_3)), Cmd_OfPromise_either((tupledArg_4) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_23;
                        return ((url_23 = addPrefix_1(tupledArg_4[0]), (refreshOn) => fetchWithDecoder_1(url_23, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_4[1]])]), "application/json", (response_11) => {
                            const pr_7 = response_11.arrayBuffer();
                            return pr_7.then((blob_8) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_8)), string_type));
                        }, refreshOn)))(true);
                    })), [`/api/sets/${SetId__get_unwrap(setId_4)}/qr-codes/assign`, body_7], (Item_26) => (new Msg(22, [Item_26])), (Item_27) => (new Msg(36, [Item_27])))))];
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 4: {
            const matchValue_11 = state.BaseSet;
            const matchValue_12 = state.Set;
            let matchResult_1, dto_4, set$_7;
            if (matchValue_11.tag === 0) {
                matchResult_1 = 1;
            }
            else if (matchValue_12.tag === 0) {
                matchResult_1 = 1;
            }
            else {
                matchResult_1 = 0;
                dto_4 = matchValue_11.fields[0];
                set$_7 = matchValue_12.fields[0];
            }
            switch (matchResult_1) {
                case 0: {
                    const dto_5 = new DataTransfer_UpdateQrCodesDto(msg.fields[0]);
                    return [state, (setId_5 = (new SetId(parse(set$_7.Id))), (body_9 = toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_UpdateQrCodesDto_$reflection(), undefined, undefined, undefined)(dto_5)), Cmd_OfPromise_either((tupledArg_5) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_26;
                        return ((url_26 = addPrefix_1(tupledArg_5[0]), (refreshOn_1) => fetchWithDecoder_1(url_26, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_5[1]])]), "application/json", (response_12) => {
                            const pr_8 = response_12.arrayBuffer();
                            return pr_8.then((blob_9) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_9)), string_type));
                        }, refreshOn_1)))(true);
                    })), [`/api/sets/${SetId__get_unwrap(setId_5)}/qr-codes/unassign`, body_9], (Item_29) => (new Msg(23, [Item_29])), (Item_30) => (new Msg(36, [Item_30])))))];
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 13: {
            const matchValue_14 = state.BaseSet;
            const matchValue_15 = state.Set;
            let matchResult_2, dto_6, set$_9;
            if (matchValue_14.tag === 0) {
                matchResult_2 = 1;
            }
            else if (matchValue_15.tag === 0) {
                matchResult_2 = 1;
            }
            else {
                matchResult_2 = 0;
                dto_6 = matchValue_14.fields[0];
                set$_9 = matchValue_15.fields[0];
            }
            switch (matchResult_2) {
                case 0: {
                    const dto_7 = new DataTransfer_CreateOrUpdateSet(dto_6.ImageUrl, dto_6.MasterData, dto_6.LocationInformation, List_distinct(append(msg.fields[0], dto_6.AssignedToolIds), {
                        Equals: (x_9, y) => (x_9 === y),
                        GetHashCode: stringHash,
                    }), dto_6.QRCodeIds, dto_6.LabelIds);
                    return [state, (setId_6 = (new SetId(parse(set$_9.Id))), (body_11 = toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_CreateOrUpdateSet_$reflection(), undefined, undefined, undefined)(dto_7)), Cmd_OfPromise_either((tupledArg_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_29;
                        return ((url_29 = addPrefix_1(tupledArg_6[0]), (refreshOn_2) => fetchWithDecoder_1(url_29, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_6[1]])]), "application/json", (response_13) => {
                            const pr_9 = response_13.arrayBuffer();
                            return pr_9.then((blob_10) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_10)), string_type));
                        }, refreshOn_2)))(true);
                    })), [`/api/sets/${SetId__get_unwrap(setId_6)}`, body_11], (Item_32) => (new Msg(21, [Item_32])), (Item_33) => (new Msg(36, [Item_33])))))];
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 14: {
            const matchValue_17 = state.BaseSet;
            const matchValue_18 = state.Set;
            let matchResult_3, dto_8, set$_11;
            if (matchValue_17.tag === 0) {
                matchResult_3 = 1;
            }
            else if (matchValue_18.tag === 0) {
                matchResult_3 = 1;
            }
            else {
                matchResult_3 = 0;
                dto_8 = matchValue_17.fields[0];
                set$_11 = matchValue_18.fields[0];
            }
            switch (matchResult_3) {
                case 0: {
                    const dto_9 = new DataTransfer_CreateOrUpdateSet(dto_8.ImageUrl, dto_8.MasterData, dto_8.LocationInformation, List_distinct(filter((toolId) => !contains(toolId, msg.fields[0], {
                        Equals: (x_11, y_1) => (x_11 === y_1),
                        GetHashCode: stringHash,
                    }), dto_8.AssignedToolIds), {
                        Equals: (x_12, y_2) => (x_12 === y_2),
                        GetHashCode: stringHash,
                    }), dto_8.QRCodeIds, dto_8.LabelIds);
                    return [state, (setId_7 = (new SetId(parse(set$_11.Id))), (body_13 = toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_CreateOrUpdateSet_$reflection(), undefined, undefined, undefined)(dto_9)), Cmd_OfPromise_either((tupledArg_7) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_32;
                        return ((url_32 = addPrefix_1(tupledArg_7[0]), (refreshOn_3) => fetchWithDecoder_1(url_32, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_7[1]])]), "application/json", (response_14) => {
                            const pr_10 = response_14.arrayBuffer();
                            return pr_10.then((blob_11) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_11)), string_type));
                        }, refreshOn_3)))(true);
                    })), [`/api/sets/${SetId__get_unwrap(setId_7)}`, body_13], (Item_35) => (new Msg(21, [Item_35])), (Item_36) => (new Msg(36, [Item_36])))))];
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 21:
            if (msg.fields[0].tag === 1) {
                return [state, Cmd_errorToast("validation.toast")];
            }
            else {
                return [state, Cmd_batch(ofArray([singleton((dispatch_5) => {
                    dispatch_5(new Msg(29, []));
                }), Cmd_successToast("general.saved_successfully")]))];
            }
        case 22: {
            const response_16 = msg.fields[0];
            if (response_16.tag === 1) {
                const matchValue_20 = tryFind((validation) => (validation.AffectedProperty === "qr_code"), response_16.fields[0].Validations);
                if (matchValue_20 == null) {
                    return [state, Cmd_errorToast("validation.toast")];
                }
                else {
                    return [state, Cmd_errorToast(matchValue_20.ErrorMessage)];
                }
            }
            else {
                return [state, Cmd_batch(ofArray([singleton((dispatch_6) => {
                    dispatch_6(new Msg(29, []));
                }), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 23:
            if (msg.fields[0].tag === 1) {
                return [state, Cmd_errorToast("validation.toast")];
            }
            else {
                return [state, Cmd_batch(ofArray([singleton((dispatch_7) => {
                    dispatch_7(new Msg(29, []));
                }), Cmd_successToast("general.saved_successfully")]))];
            }
        case 20:
            return [new State(state.Set, state.Tools, state.UserConfiguration, state.UserData, state.Customer, state.ImageViewerOpen, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, state.Storages, new RequestedValue$1(1, [msg.fields[0]]), state.ProblemReports, state.Notifications, state.Changelogs), Cmd_none()];
        case 30:
            return [state, getAssignableTools((Item_38) => (new Msg(25, [Item_38])), (Item_39) => (new Msg(36, [Item_39])))];
        case 25:
            return [new State(state.Set, new RequestedValue$1(1, [msg.fields[0]]), state.UserConfiguration, state.UserData, state.Customer, state.ImageViewerOpen, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, state.Storages, state.BaseSet, state.ProblemReports, state.Notifications, state.Changelogs), Cmd_none()];
        case 19:
            return [state, getBlob(msg.fields[0], msg.fields[1], (tupledArg_8) => (new Msg(12, [tupledArg_8[0], tupledArg_8[1]])), (Item_42) => (new Msg(36, [Item_42])))];
        case 27:
            return [new State(state.Set, state.Tools, state.UserConfiguration, state.UserData, state.Customer, state.ImageViewerOpen, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, new RequestedValue$1(1, [msg.fields[0]]), state.BaseSet, state.ProblemReports, state.Notifications, state.Changelogs), Cmd_none()];
        case 24:
            return [new State(state.Set, state.Tools, state.UserConfiguration, state.UserData, state.Customer, state.ImageViewerOpen, state.QuickOverviewDialog, new RequestedValue$1(1, [msg.fields[0]]), state.CustomerConfiguration, state.Storages, state.BaseSet, state.ProblemReports, state.Notifications, state.Changelogs), Cmd_none()];
        case 28:
            return [new State(state.Set, state.Tools, state.UserConfiguration, state.UserData, state.Customer, state.ImageViewerOpen, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, state.Storages, state.BaseSet, new RequestedValue$1(1, [msg.fields[0]]), state.Notifications, state.Changelogs), Cmd_none()];
        case 26:
            return [new State(state.Set, state.Tools, state.UserConfiguration, state.UserData, state.Customer, state.ImageViewerOpen, state.QuickOverviewDialog, state.Documents, new RequestedValue$1(1, [msg.fields[0]]), state.Storages, state.BaseSet, state.ProblemReports, state.Notifications, state.Changelogs), Cmd_none()];
        case 31: {
            const matchValue_21 = state.Set;
            if (matchValue_21.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, getSetNotifications(new SetId(parse(matchValue_21.fields[0].Id)), (Item_44) => (new Msg(32, [Item_44])), (Item_45) => (new Msg(36, [Item_45])))];
            }
        }
        case 32:
            return [new State(state.Set, state.Tools, state.UserConfiguration, state.UserData, state.Customer, state.ImageViewerOpen, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, state.Storages, state.BaseSet, state.ProblemReports, new RequestedValue$1(1, [msg.fields[0]]), state.Changelogs), Cmd_none()];
        case 34: {
            const matchValue_22 = state.Set;
            if (matchValue_22.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, getChangelogs(matchValue_22.fields[0].Id, (Item_46) => (new Msg(35, [Item_46])), (Item_47) => (new Msg(36, [Item_47])))];
            }
        }
        case 35:
            return [new State(state.Set, state.Tools, state.UserConfiguration, state.UserData, state.Customer, state.ImageViewerOpen, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, state.Storages, state.BaseSet, state.ProblemReports, state.Notifications, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 36:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.UserConfiguration;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const userConfiguration = matchValue.fields[0];
                return [new State(state.Set, state.Tools, state.UserConfiguration, state.UserData, state.Customer, state.ImageViewerOpen, {
                    IsLoading: true,
                    IsOpen: state.QuickOverviewDialog.IsOpen,
                }, state.Documents, state.CustomerConfiguration, state.Storages, state.BaseSet, state.ProblemReports, state.Notifications, state.Changelogs), (userConfiguration_1 = (new UserConfiguration_1(userConfiguration.TableConfiguration, (bind$0040_1 = userConfiguration.QuickOverviewConfiguration, new QuickOverviewConfiguration_1(bind$0040_1.ConsumableViewVisibleFieldKeys, bind$0040_1.ToolWithQuantityViewVisibleFieldKeys, msg.fields[0])))), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                    const pr = response.arrayBuffer();
                    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), EmptyResponse_$reflection()));
                })))), ["/api/users/configuration", toString(0, Auto_generateBoxedEncoder_437914C6(UserConfiguration_$reflection(), undefined, undefined, undefined)(userConfiguration_1))], (Item) => (new Msg(15, [Item])), (Item_1) => (new Msg(36, [Item_1]))))];
            }
        }
    }
}

