import { unwrapDispolistId } from "../Shared/Helper.js";
import { Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../Communication.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { map, filter, cons, empty, ofArray, singleton } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { ConsumableElement, Element$, ToolWithQuantityElement, Helper_elementId, Helper_containsElement, Helper_toElement, ElementType, Dispolist, DispolistResponse_$reflection, DispolistElement_$reflection, DispolistOverview_$reflection, Dispolist_$reflection } from "../Shared/Dispolist.js";
import { isNullOrWhiteSpace, printf, toText } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { LabelRow, EditDispolistForm, NewDispolistForm, State, ReceiverTypeForm as ReceiverTypeForm_1, SelectedTab, DispolistForm as DispolistForm_20, LabelForm as LabelForm_4, Msg } from "./Types.js";
import { list_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { CustomerConfiguration as CustomerConfiguration_2, DispolistId, Label, LabelId, LabelResponse_$reflection, Label_$reflection, PostResponse$1_$reflection } from "../Shared/Shared.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { utcNow } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { updateCustomerConfiguration, getCustomerConfiguration } from "../Requests/Configuration.js";
import { Cmd_successToast, Cmd_errorToast } from "../Components/Toast.js";
import { Cmd_ofEffect } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";

/**
 * Dispolist commands
 */
export function getDispolistCmd(dispolistId) {
    const dispolistId_1 = unwrapDispolistId(dispolistId);
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Dispolist_$reflection()));
    })))), toText(printf("/api/dispolists/%s"))(dispolistId_1), (Item) => (new Msg(25, [Item])), (Item_1) => (new Msg(32, [Item_1])));
}

export const getDispolistsCmd = Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
    const pr = response.arrayBuffer();
    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(DispolistOverview_$reflection())));
})))), "/api/dispolists", (Item) => (new Msg(26, [Item])), (Item_1) => (new Msg(32, [Item_1])));

export const getDispolistToolsCmd = Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
    const pr = response.arrayBuffer();
    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(DispolistElement_$reflection())));
})))), "/api/dispolists/tools", (Item) => (new Msg(11, [Item])), (Item_1) => (new Msg(32, [Item_1])));

export function postDispolistCmd(dispolist) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(DispolistResponse_$reflection())));
    })))), ["/api/dispolists", toString(0, Auto_generateBoxedEncoder_437914C6(Dispolist_$reflection(), undefined, undefined, undefined)(dispolist))], (Item) => (new Msg(19, [Item])), (Item_1) => (new Msg(32, [Item_1])));
}

export function putDispolistCmd(dispolist) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(DispolistResponse_$reflection())));
    })))), ["/api/dispolists", toString(0, Auto_generateBoxedEncoder_437914C6(Dispolist_$reflection(), undefined, undefined, undefined)(dispolist))], (Item) => (new Msg(19, [Item])), (Item_1) => (new Msg(32, [Item_1])));
}

export const getLabelsCmd = Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
    const pr = response.arrayBuffer();
    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Label_$reflection())));
})))), "/api/labels", (Item) => (new Msg(24, [Item])), (Item_1) => (new Msg(32, [Item_1])));

export function getLabelCmd(labelId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Label_$reflection()));
    })))), toText(printf("/api/labels/%s"))(labelId.fields[0]), (Item) => (new Msg(23, [Item])), (Item_1) => (new Msg(32, [Item_1])));
}

export function postLabelCmd(label) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(LabelResponse_$reflection())));
    })))), ["/api/labels", toString(0, Auto_generateBoxedEncoder_437914C6(Label_$reflection(), undefined, undefined, undefined)(label))], (Item) => (new Msg(27, [Item])), (Item_1) => (new Msg(32, [Item_1])));
}

export function putLabelCmd(label) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(LabelResponse_$reflection())));
    })))), ["/api/labels", toString(0, Auto_generateBoxedEncoder_437914C6(Label_$reflection(), undefined, undefined, undefined)(label))], (Item) => (new Msg(27, [Item])), (Item_1) => (new Msg(32, [Item_1])));
}

export const emtptyLabel = new Label(new LabelId("00000000-0000-0000-0000-000000000000"), "", "#9A9FFF", true);

export const emptyDispolist = new Dispolist(new DispolistId("00000000-0000-0000-0000-000000000000"), "", "", empty(), utcNow(), utcNow(), false);

export const initialState = new State(empty(), empty(), new LabelForm_4(emtptyLabel), empty(), new DispolistForm_20(2, [emptyDispolist]), empty(), undefined, new SelectedTab(), undefined, false, false, new ReceiverTypeForm_1(true, undefined));

export function init() {
    return [initialState, Cmd_batch(ofArray([getLabelsCmd, getCustomerConfiguration((Item) => (new Msg(28, [Item])), (Item_1) => (new Msg(32, [Item_1]))), getDispolistsCmd]))];
}

export function initNewLabel() {
    return [initialState, Cmd_none()];
}

export function initViewOrEditLabel(labelId) {
    return [initialState, Cmd_batch(ofArray([getLabelCmd(new LabelId(labelId)), getLabelsCmd]))];
}

export function initNewDispolist() {
    return [new State(initialState.Labels, initialState.LabelTable, initialState.LabelForm, initialState.Dispolists, new DispolistForm_20(0, [new NewDispolistForm(emptyDispolist, new ElementType(1, []), undefined)]), initialState.Tools, initialState.CustomerConfiguration, initialState.SelectedTab, initialState.Message, initialState.IsValidated, initialState.IsLabelColorSelectorOpen, initialState.ReceiverTypeForm), getDispolistToolsCmd];
}

export function initViewDispolist(dispolistId) {
    return [initialState, getDispolistCmd(new DispolistId(dispolistId))];
}

export function initEditDispolist(dispolistId) {
    return [new State(initialState.Labels, initialState.LabelTable, initialState.LabelForm, initialState.Dispolists, new DispolistForm_20(1, [new EditDispolistForm(emptyDispolist, new ElementType(1, []), undefined)]), initialState.Tools, initialState.CustomerConfiguration, initialState.SelectedTab, initialState.Message, initialState.IsValidated, initialState.IsLabelColorSelectorOpen, initialState.ReceiverTypeForm), Cmd_batch(ofArray([getDispolistCmd(new DispolistId(dispolistId)), getDispolistToolsCmd]))];
}

export function update(msg, state) {
    let bind$0040_1, bind$0040, bind$0040_3, bind$0040_2, bind$0040_5, bind$0040_4, bind$0040_7, bind$0040_6, bind$0040_9, bind$0040_8, bind$0040_11, bind$0040_10, fullPath_2, matchValue_18, receiverTypeForm_1, matchValue_19, matchValue_20, config_1, rc, fullPath_4;
    switch (msg.tag) {
        case 1: {
            const label = state.LabelForm.fields[0];
            return [new State(state.Labels, state.LabelTable, new LabelForm_4(new Label(label.Id, msg.fields[0], label.ColorHex, label.VisibleForUserRole)), state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
        }
        case 2: {
            const label_1 = state.LabelForm.fields[0];
            return [new State(state.Labels, state.LabelTable, new LabelForm_4(new Label(label_1.Id, label_1.Name, msg.fields[0], label_1.VisibleForUserRole)), state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
        }
        case 3: {
            const label_2 = state.LabelForm.fields[0];
            return [new State(state.Labels, state.LabelTable, new LabelForm_4(new Label(label_2.Id, label_2.Name, label_2.ColorHex, msg.fields[0])), state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
        }
        case 4:
            return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, msg.fields[0], state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
        case 5: {
            msg.fields[0].preventDefault();
            const label_3 = state.LabelForm.fields[0];
            if (isNullOrWhiteSpace(label_3.Name)) {
                return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, true, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
            }
            else {
                return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, undefined, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), postLabelCmd(label_3)];
            }
        }
        case 6: {
            msg.fields[0].preventDefault();
            const label_4 = state.LabelForm.fields[0];
            if (isNullOrWhiteSpace(label_4.Name)) {
                return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, true, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
            }
            else {
                return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, undefined, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), putLabelCmd(label_4)];
            }
        }
        case 7:
            return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, !state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
        case 9: {
            const name_1 = msg.fields[0];
            const matchValue_5 = state.DispolistForm;
            switch (matchValue_5.tag) {
                case 1: {
                    const dispolist_1 = matchValue_5.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(1, [new EditDispolistForm((bind$0040_1 = dispolist_1.Dispolist, new Dispolist(bind$0040_1.Id, name_1, bind$0040_1.Description, bind$0040_1.Elements, bind$0040_1.CreatedAt, bind$0040_1.UpdatedAt, bind$0040_1.IsDeleted)), dispolist_1.CurrentDispolistSelectElementType, dispolist_1.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
                case 2: {
                    const dispolist_2 = matchValue_5.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(2, [new Dispolist(dispolist_2.Id, name_1, dispolist_2.Description, dispolist_2.Elements, dispolist_2.CreatedAt, dispolist_2.UpdatedAt, dispolist_2.IsDeleted)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
                default: {
                    const dispolist = matchValue_5.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(0, [new NewDispolistForm((bind$0040 = dispolist.Dispolist, new Dispolist(bind$0040.Id, name_1, bind$0040.Description, bind$0040.Elements, bind$0040.CreatedAt, bind$0040.UpdatedAt, bind$0040.IsDeleted)), dispolist.CurrentDispolistSelectElementType, dispolist.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
            }
        }
        case 10: {
            const description = msg.fields[0];
            const matchValue_6 = state.DispolistForm;
            switch (matchValue_6.tag) {
                case 1: {
                    const dispolist_4 = matchValue_6.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(1, [new EditDispolistForm((bind$0040_3 = dispolist_4.Dispolist, new Dispolist(bind$0040_3.Id, bind$0040_3.Name, description, bind$0040_3.Elements, bind$0040_3.CreatedAt, bind$0040_3.UpdatedAt, bind$0040_3.IsDeleted)), dispolist_4.CurrentDispolistSelectElementType, dispolist_4.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
                case 2: {
                    const dispolist_5 = matchValue_6.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(2, [new Dispolist(dispolist_5.Id, dispolist_5.Name, description, dispolist_5.Elements, dispolist_5.CreatedAt, dispolist_5.UpdatedAt, dispolist_5.IsDeleted)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
                default: {
                    const dispolist_3 = matchValue_6.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(0, [new NewDispolistForm((bind$0040_2 = dispolist_3.Dispolist, new Dispolist(bind$0040_2.Id, bind$0040_2.Name, description, bind$0040_2.Elements, bind$0040_2.CreatedAt, bind$0040_2.UpdatedAt, bind$0040_2.IsDeleted)), dispolist_3.CurrentDispolistSelectElementType, dispolist_3.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
            }
        }
        case 11:
            return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, msg.fields[0], state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
        case 12: {
            const selectedToolType = msg.fields[0];
            const matchValue_7 = state.DispolistForm;
            switch (matchValue_7.tag) {
                case 1: {
                    const dispolist_7 = matchValue_7.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(1, [new EditDispolistForm(dispolist_7.Dispolist, selectedToolType, dispolist_7.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
                case 2:
                    return [state, Cmd_none()];
                default: {
                    const dispolist_6 = matchValue_7.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(0, [new NewDispolistForm(dispolist_6.Dispolist, selectedToolType, dispolist_6.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
            }
        }
        case 13: {
            const selectedTool = msg.fields[0];
            const matchValue_8 = state.DispolistForm;
            switch (matchValue_8.tag) {
                case 1: {
                    const dispolist_9 = matchValue_8.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(1, [new EditDispolistForm(dispolist_9.Dispolist, dispolist_9.CurrentDispolistSelectElementType, selectedTool)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
                case 2:
                    return [state, Cmd_none()];
                default: {
                    const dispolist_8 = matchValue_8.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(0, [new NewDispolistForm(dispolist_8.Dispolist, dispolist_8.CurrentDispolistSelectElementType, selectedTool)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
            }
        }
        case 14: {
            msg.fields[0].preventDefault();
            const matchValue_9 = state.DispolistForm;
            switch (matchValue_9.tag) {
                case 1: {
                    const dispolist_11 = matchValue_9.fields[0];
                    const matchValue_11 = dispolist_11.CurrentElement;
                    if (matchValue_11 == null) {
                        return [state, Cmd_none()];
                    }
                    else {
                        const newElement_1 = Helper_toElement(matchValue_11);
                        if (Helper_containsElement(dispolist_11.Dispolist, newElement_1)) {
                            return [state, Cmd_none()];
                        }
                        else {
                            return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(1, [new EditDispolistForm((bind$0040_5 = dispolist_11.Dispolist, new Dispolist(bind$0040_5.Id, bind$0040_5.Name, bind$0040_5.Description, cons(newElement_1, dispolist_11.Dispolist.Elements), bind$0040_5.CreatedAt, bind$0040_5.UpdatedAt, bind$0040_5.IsDeleted)), dispolist_11.CurrentDispolistSelectElementType, dispolist_11.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                        }
                    }
                }
                case 2:
                    return [state, Cmd_none()];
                default: {
                    const dispolist_10 = matchValue_9.fields[0];
                    const matchValue_10 = dispolist_10.CurrentElement;
                    if (matchValue_10 == null) {
                        return [state, Cmd_none()];
                    }
                    else {
                        const newElement = Helper_toElement(matchValue_10);
                        if (Helper_containsElement(dispolist_10.Dispolist, newElement)) {
                            return [state, Cmd_none()];
                        }
                        else {
                            return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(0, [new NewDispolistForm((bind$0040_4 = dispolist_10.Dispolist, new Dispolist(bind$0040_4.Id, bind$0040_4.Name, bind$0040_4.Description, cons(newElement, dispolist_10.Dispolist.Elements), bind$0040_4.CreatedAt, bind$0040_4.UpdatedAt, bind$0040_4.IsDeleted)), dispolist_10.CurrentDispolistSelectElementType, dispolist_10.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                        }
                    }
                }
            }
        }
        case 15: {
            const id = msg.fields[0];
            const matchValue_12 = state.DispolistForm;
            switch (matchValue_12.tag) {
                case 1: {
                    const dispolist_13 = matchValue_12.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(1, [new EditDispolistForm((bind$0040_7 = dispolist_13.Dispolist, new Dispolist(bind$0040_7.Id, bind$0040_7.Name, bind$0040_7.Description, filter((e_1) => (Helper_elementId(e_1) !== id), dispolist_13.Dispolist.Elements), bind$0040_7.CreatedAt, bind$0040_7.UpdatedAt, bind$0040_7.IsDeleted)), dispolist_13.CurrentDispolistSelectElementType, dispolist_13.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
                case 2:
                    return [state, Cmd_none()];
                default: {
                    const dispolist_12 = matchValue_12.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(0, [new NewDispolistForm((bind$0040_6 = dispolist_12.Dispolist, new Dispolist(bind$0040_6.Id, bind$0040_6.Name, bind$0040_6.Description, filter((e) => (Helper_elementId(e) !== id), dispolist_12.Dispolist.Elements), bind$0040_6.CreatedAt, bind$0040_6.UpdatedAt, bind$0040_6.IsDeleted)), dispolist_12.CurrentDispolistSelectElementType, dispolist_12.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
            }
        }
        case 16: {
            const validatedQuantity = msg.fields[1] | 0;
            const id_1 = msg.fields[0];
            const matchValue_13 = state.DispolistForm;
            switch (matchValue_13.tag) {
                case 1: {
                    const dispolist_15 = matchValue_13.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(1, [new EditDispolistForm((bind$0040_9 = dispolist_15.Dispolist, new Dispolist(bind$0040_9.Id, bind$0040_9.Name, bind$0040_9.Description, map((e_3) => {
                        if (Helper_elementId(e_3) === id_1) {
                            switch (e_3.tag) {
                                case 2: {
                                    const t_1 = e_3.fields[0];
                                    return new Element$(2, [new ToolWithQuantityElement(t_1.ToolId, t_1.Id, t_1.DisplayName, validatedQuantity, t_1.Type)]);
                                }
                                default:
                                    return e_3;
                            }
                        }
                        else {
                            return e_3;
                        }
                    }, dispolist_15.Dispolist.Elements), bind$0040_9.CreatedAt, bind$0040_9.UpdatedAt, bind$0040_9.IsDeleted)), dispolist_15.CurrentDispolistSelectElementType, dispolist_15.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
                case 2:
                    return [state, Cmd_none()];
                default: {
                    const dispolist_14 = matchValue_13.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(0, [new NewDispolistForm((bind$0040_8 = dispolist_14.Dispolist, new Dispolist(bind$0040_8.Id, bind$0040_8.Name, bind$0040_8.Description, map((e_2) => {
                        if (Helper_elementId(e_2) === id_1) {
                            switch (e_2.tag) {
                                case 2: {
                                    const t = e_2.fields[0];
                                    return new Element$(2, [new ToolWithQuantityElement(t.ToolId, t.Id, t.DisplayName, validatedQuantity, t.Type)]);
                                }
                                default:
                                    return e_2;
                            }
                        }
                        else {
                            return e_2;
                        }
                    }, dispolist_14.Dispolist.Elements), bind$0040_8.CreatedAt, bind$0040_8.UpdatedAt, bind$0040_8.IsDeleted)), dispolist_14.CurrentDispolistSelectElementType, dispolist_14.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
            }
        }
        case 17: {
            const validatedQuantity_1 = msg.fields[1];
            const id_2 = msg.fields[0];
            const matchValue_14 = state.DispolistForm;
            switch (matchValue_14.tag) {
                case 1: {
                    const dispolist_17 = matchValue_14.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(1, [new EditDispolistForm((bind$0040_11 = dispolist_17.Dispolist, new Dispolist(bind$0040_11.Id, bind$0040_11.Name, bind$0040_11.Description, map((e_5) => {
                        if (Helper_elementId(e_5) === id_2) {
                            switch (e_5.tag) {
                                case 0: {
                                    const c_1 = e_5.fields[0];
                                    return new Element$(0, [new ConsumableElement(c_1.ConsumableId, c_1.Id, c_1.DisplayName, c_1.Unit, validatedQuantity_1, c_1.Type)]);
                                }
                                default:
                                    return e_5;
                            }
                        }
                        else {
                            return e_5;
                        }
                    }, dispolist_17.Dispolist.Elements), bind$0040_11.CreatedAt, bind$0040_11.UpdatedAt, bind$0040_11.IsDeleted)), dispolist_17.CurrentDispolistSelectElementType, dispolist_17.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
                case 2:
                    return [state, Cmd_none()];
                default: {
                    const dispolist_16 = matchValue_14.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(0, [new NewDispolistForm((bind$0040_10 = dispolist_16.Dispolist, new Dispolist(bind$0040_10.Id, bind$0040_10.Name, bind$0040_10.Description, map((e_4) => {
                        if (Helper_elementId(e_4) === id_2) {
                            switch (e_4.tag) {
                                case 0: {
                                    const c = e_4.fields[0];
                                    return new Element$(0, [new ConsumableElement(c.ConsumableId, c.Id, c.DisplayName, c.Unit, validatedQuantity_1, c.Type)]);
                                }
                                default:
                                    return e_4;
                            }
                        }
                        else {
                            return e_4;
                        }
                    }, dispolist_16.Dispolist.Elements), bind$0040_10.CreatedAt, bind$0040_10.UpdatedAt, bind$0040_10.IsDeleted)), dispolist_16.CurrentDispolistSelectElementType, dispolist_16.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
            }
        }
        case 18: {
            msg.fields[0].preventDefault();
            const matchValue_15 = state.DispolistForm;
            switch (matchValue_15.tag) {
                case 1: {
                    const form_1 = matchValue_15.fields[0];
                    if (isNullOrWhiteSpace(form_1.Dispolist.Name) ? true : true) {
                        return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, true, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                    }
                    else {
                        return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, undefined, false, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), putDispolistCmd(form_1.Dispolist)];
                    }
                }
                case 2:
                    return [state, Cmd_none()];
                default: {
                    const form = matchValue_15.fields[0];
                    if (isNullOrWhiteSpace(form.Dispolist.Name) ? true : true) {
                        return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, true, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                    }
                    else {
                        return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, undefined, false, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), postDispolistCmd(form.Dispolist)];
                    }
                }
            }
        }
        case 19: {
            let patternInput;
            const matchValue_16 = msg.fields[0].Result;
            let matchResult, dispolistId;
            switch (matchValue_16.tag) {
                case 0: {
                    matchResult = 1;
                    dispolistId = matchValue_16.fields[0];
                    break;
                }
                case 1: {
                    matchResult = 1;
                    dispolistId = matchValue_16.fields[0];
                    break;
                }
                default:
                    matchResult = 0;
            }
            switch (matchResult) {
                case 0: {
                    patternInput = [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, "dispolist.dispolist_exists_already", state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_errorToast("dispolist.dispolist_exists_already")];
                    break;
                }
                default:
                    patternInput = [state, Cmd_batch(ofArray([(fullPath_2 = toText(printf("/settings/view/dispolist/%O"))(dispolistId.fields[0]), Cmd_ofEffect((_arg_7) => {
                        RouterModule_nav(singleton(fullPath_2), 1, 1);
                    })), Cmd_successToast("general.saved_successfully")]))];
            }
            return [patternInput[0], patternInput[1]];
        }
        case 25: {
            const response_1 = msg.fields[0];
            const matchValue_17 = state.DispolistForm;
            switch (matchValue_17.tag) {
                case 1: {
                    const form_2 = matchValue_17.fields[0];
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(1, [new EditDispolistForm(response_1, form_2.CurrentDispolistSelectElementType, form_2.CurrentElement)]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                }
                case 2:
                    return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, new DispolistForm_20(2, [response_1]), state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
                default:
                    return [state, Cmd_none()];
            }
        }
        case 20:
            return [state.ReceiverTypeForm.IsFormDisabled ? (new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, new ReceiverTypeForm_1(false, (matchValue_18 = state.CustomerConfiguration, (matchValue_18 == null) ? undefined : matchValue_18.ReceiverVisibilityConfiguration)))) : ((receiverTypeForm_1 = (new ReceiverTypeForm_1(true, undefined)), new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, (matchValue_19 = state.CustomerConfiguration, (matchValue_20 = state.ReceiverTypeForm.OldReceiverVisibilityConfiguration, (matchValue_19 != null) ? ((matchValue_20 != null) ? ((config_1 = matchValue_19, (rc = matchValue_20, new CustomerConfiguration_2(config_1.Staging, config_1.IsGlobalAppSearchActiveForUserRole, config_1.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, config_1.IsSelfServiceReservationForUserActive, rc, config_1.NotificationEmailsOnlyToAdministrators, config_1.DemoDataImported, config_1.CanUserSeeAllTools, config_1.CanUserWriteNotices, config_1.AppVisibilityPermissionReceiver, config_1.GlobalSelfServiceStorageActive, config_1.CustomI18nextNamespace, config_1.Tenants, config_1.TenantName, config_1.ReceiverCreateReservationAllowedConfiguration, config_1.AllowUserToUserAssignmentViaUserSelection, config_1.IsReleaseDateEnabled, config_1.ToolManagerCanCreateReceiver, config_1.SSOConfiguration)))) : undefined) : undefined)), state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, receiverTypeForm_1))), Cmd_none()];
        case 21: {
            const matchValue_22 = state.CustomerConfiguration;
            if (matchValue_22 != null) {
                const configuration = matchValue_22;
                return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, new CustomerConfiguration_2(configuration.Staging, configuration.IsGlobalAppSearchActiveForUserRole, configuration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, configuration.IsSelfServiceReservationForUserActive, msg.fields[0], configuration.NotificationEmailsOnlyToAdministrators, configuration.DemoDataImported, configuration.CanUserSeeAllTools, configuration.CanUserWriteNotices, configuration.AppVisibilityPermissionReceiver, configuration.GlobalSelfServiceStorageActive, configuration.CustomI18nextNamespace, configuration.Tenants, configuration.TenantName, configuration.ReceiverCreateReservationAllowedConfiguration, configuration.AllowUserToUserAssignmentViaUserSelection, configuration.IsReleaseDateEnabled, configuration.ToolManagerCanCreateReceiver, configuration.SSOConfiguration), state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 23:
            return [new State(state.Labels, state.LabelTable, new LabelForm_4(msg.fields[0]), state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
        case 24: {
            const labels = msg.fields[0];
            return [new State(labels, map((label_6) => (new LabelRow(label_6.Id.fields[0], label_6.Name, label_6.ColorHex)), labels), state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
        }
        case 27: {
            let patternInput_2;
            const matchValue_23 = msg.fields[0].Result;
            patternInput_2 = ((matchValue_23.tag === 0) ? [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, "settings.label_exists_msg", state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_errorToast("settings.label_exists_msg")] : [state, Cmd_batch(ofArray([(fullPath_4 = toText(printf("/settings/view/label/%O"))(matchValue_23.fields[0].fields[0]), Cmd_ofEffect((_arg_8) => {
                RouterModule_nav(singleton(fullPath_4), 1, 1);
            })), Cmd_successToast("general.saved_successfully")]))]);
            return [patternInput_2[0], patternInput_2[1]];
        }
        case 30:
            return [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg_9) => {
                RouterModule_nav(singleton("/settings"), 1, 1);
            }), Cmd_successToast("general.deleted_successfully")]))];
        case 31:
            return [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg_10) => {
                RouterModule_nav(singleton("/settings"), 1, 1);
            }), Cmd_successToast("general.deleted_successfully")]))];
        case 26:
            return [new State(state.Labels, state.LabelTable, state.LabelForm, msg.fields[0], state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
        case 22: {
            const matchValue_25 = state.CustomerConfiguration;
            if (matchValue_25 == null) {
                return [state, Cmd_none()];
            }
            else {
                return [state, updateCustomerConfiguration(matchValue_25, (Item_27) => (new Msg(29, [Item_27])), (Item_28) => (new Msg(32, [Item_28])))];
            }
        }
        case 8:
            return [state, updateCustomerConfiguration(msg.fields[0], (Item_29) => (new Msg(29, [Item_29])), (Item_30) => (new Msg(32, [Item_30])))];
        case 28:
            return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, msg.fields[0], state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.ReceiverTypeForm), Cmd_none()];
        case 29:
            return [new State(state.Labels, state.LabelTable, state.LabelForm, state.Dispolists, state.DispolistForm, state.Tools, state.CustomerConfiguration, state.SelectedTab, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, new ReceiverTypeForm_1(true, undefined)), (msg.fields[0].Result.tag === 1) ? Cmd_batch(ofArray([Cmd_errorToast("settings.receiver_failed_deactivate"), getCustomerConfiguration((Item_33) => (new Msg(28, [Item_33])), (Item_34) => (new Msg(32, [Item_34])))])) : getCustomerConfiguration((Item_31) => (new Msg(28, [Item_31])), (Item_32) => (new Msg(32, [Item_32])))];
        case 32:
            return [state, Cmd_none()];
        default:
            return [state, Cmd_ofEffect((_arg) => {
                RouterModule_nav(singleton("/settings/new/label"), 1, 1);
            })];
    }
}

