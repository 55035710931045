import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { RequestedValue$1_$reflection, StocktakingId_$reflection } from "../../Shared/Shared.js";
import { StocktakingDetailDto_$reflection } from "../../Shared/Stocktaking.js";
import { union_type, class_type, record_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class State extends Record {
    constructor(StocktakingId, Stocktaking) {
        super();
        this.StocktakingId = StocktakingId;
        this.Stocktaking = Stocktaking;
    }
}

export function State_$reflection() {
    return record_type("Stocktaking.Result.Types.State", [], State, () => [["StocktakingId", StocktakingId_$reflection()], ["Stocktaking", RequestedValue$1_$reflection(StocktakingDetailDto_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FetchStocktaking", "StocktakingFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Stocktaking.Result.Types.Msg", [], Msg, () => [[], [["Item", StocktakingDetailDto_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

