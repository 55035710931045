import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { ClientApiResponse$1_$reflection, FormState_$reflection, SSOConfiguration_$reflection, RequestedValue$1_$reflection, CustomerConfiguration_$reflection } from "../../../Shared/Shared.js";
import { union_type, class_type, string_type, bool_type, record_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class State extends Record {
    constructor(CustomerConfiguration, SSOConfiguration, FormState) {
        super();
        this.CustomerConfiguration = CustomerConfiguration;
        this.SSOConfiguration = SSOConfiguration;
        this.FormState = FormState;
    }
}

export function State_$reflection() {
    return record_type("Settings.SSO.Types.State", [], State, () => [["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["SSOConfiguration", RequestedValue$1_$reflection(SSOConfiguration_$reflection())], ["FormState", FormState_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSSOIsActive", "SetClientId", "SetClientSecret", "SetOpenIdConnectDiscoveryUrl", "SetAllowedHosts", "CustomerConfigurationFetched", "UpdateCustomerConfiguration", "CustomerConfigurationUpdated", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Settings.SSO.Types.Msg", [], Msg, () => [[["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", CustomerConfiguration_$reflection()]], [], [["Item", ClientApiResponse$1_$reflection(CustomerConfiguration_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

