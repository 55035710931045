import { RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { getStocktaking } from "../../Requests/Stocktaking.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";

export function initViewStocktakingResult(stocktakingId) {
    return [new State(stocktakingId, new RequestedValue$1(0, [])), getStocktaking(stocktakingId, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.StocktakingId, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 2:
            return [state, Cmd_none()];
        default:
            return [state, getStocktaking(state.StocktakingId, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])))];
    }
}

