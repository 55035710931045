import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { anonRecord_type, list_type, option_type, union_type, record_type, bool_type, class_type, string_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ClientApiResponse$1_$reflection, EmptyResponse_$reflection, ImageDeletedResponse_$reflection, PostResponse$1_$reflection, CustomerConfiguration_$reflection, DocumentDetail_$reflection, Customer_$reflection, TokenDataDto_$reflection, RequestedValue$1_$reflection, DocumentId_$reflection, ProblemReportId_$reflection } from "../../../Shared/Shared.js";
import { ToolNoticeResponse_$reflection, DeleteQRCodeResult_$reflection, AssignQRCodeResult_$reflection, ToolHistoryDto_$reflection, ProblemReport_$reflection, ToolDetailDto_$reflection } from "../../../Shared/Tool.js";
import { Storage_$reflection } from "../../../Shared/Storage.js";
import { UserConfiguration_$reflection } from "../../../Shared/User.js";
import { OverviewNotification_$reflection } from "../../../Shared/Notification.js";
import { ChangelogDto_$reflection } from "../../../Shared/Changelog.js";
import { Message_$reflection } from "../../../Widgets/ChatWidget.js";

export class ToolReminderRow extends Record {
    constructor(Id, Date$, Title, Description, IsConfirmed, IsConfirmedAsString, IsInterval, Interval, ConfirmationComment) {
        super();
        this.Id = Id;
        this.Date = Date$;
        this.Title = Title;
        this.Description = Description;
        this.IsConfirmed = IsConfirmed;
        this.IsConfirmedAsString = IsConfirmedAsString;
        this.IsInterval = IsInterval;
        this.Interval = Interval;
        this.ConfirmationComment = ConfirmationComment;
    }
}

export function ToolReminderRow_$reflection() {
    return record_type("Tools.Tool.Details.Types.ToolReminderRow", [], ToolReminderRow, () => [["Id", string_type], ["Date", class_type("System.DateTime")], ["Title", string_type], ["Description", string_type], ["IsConfirmed", bool_type], ["IsConfirmedAsString", string_type], ["IsInterval", bool_type], ["Interval", string_type], ["ConfirmationComment", string_type]]);
}

export class ProblemReportRow extends Record {
    constructor(Id, Solver, Sender, State, Message, Timestamp) {
        super();
        this.Id = Id;
        this.Solver = Solver;
        this.Sender = Sender;
        this.State = State;
        this.Message = Message;
        this.Timestamp = Timestamp;
    }
}

export function ProblemReportRow_$reflection() {
    return record_type("Tools.Tool.Details.Types.ProblemReportRow", [], ProblemReportRow, () => [["Id", ProblemReportId_$reflection()], ["Solver", string_type], ["Sender", string_type], ["State", string_type], ["Message", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class DocumentRow extends Record {
    constructor(UploadDate, Name, Description, IsVisibleForUserRoleAsString, Id) {
        super();
        this.UploadDate = UploadDate;
        this.Name = Name;
        this.Description = Description;
        this.IsVisibleForUserRoleAsString = IsVisibleForUserRoleAsString;
        this.Id = Id;
    }
}

export function DocumentRow_$reflection() {
    return record_type("Tools.Tool.Details.Types.DocumentRow", [], DocumentRow, () => [["UploadDate", class_type("System.DateTime")], ["Name", string_type], ["Description", string_type], ["IsVisibleForUserRoleAsString", string_type], ["Id", DocumentId_$reflection()]]);
}

export class PlanningTypeId extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ReservationId", "ReminderId"];
    }
}

export function PlanningTypeId_$reflection() {
    return union_type("Tools.Tool.Details.Types.PlanningTypeId", [], PlanningTypeId, () => [[["Item", string_type]], [["Item", string_type]]]);
}

export class Planning extends Record {
    constructor(Type, StartDate, EndDate, ReservationName, ReceiverName, Description, PlanningTypeId) {
        super();
        this.Type = Type;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.ReservationName = ReservationName;
        this.ReceiverName = ReceiverName;
        this.Description = Description;
        this.PlanningTypeId = PlanningTypeId;
    }
}

export function Planning_$reflection() {
    return record_type("Tools.Tool.Details.Types.Planning", [], Planning, () => [["Type", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", option_type(class_type("System.DateTime"))], ["ReservationName", string_type], ["ReceiverName", string_type], ["Description", string_type], ["PlanningTypeId", PlanningTypeId_$reflection()]]);
}

export class State extends Record {
    constructor(Tool, Storages, UserConfiguration, ProblemReports, UserData, Customer, ImageViewerOpen, Manufacturers, QuickOverviewDialog, Documents, CustomerConfiguration, History, Notifications, Changelogs) {
        super();
        this.Tool = Tool;
        this.Storages = Storages;
        this.UserConfiguration = UserConfiguration;
        this.ProblemReports = ProblemReports;
        this.UserData = UserData;
        this.Customer = Customer;
        this.ImageViewerOpen = ImageViewerOpen;
        this.Manufacturers = Manufacturers;
        this.QuickOverviewDialog = QuickOverviewDialog;
        this.Documents = Documents;
        this.CustomerConfiguration = CustomerConfiguration;
        this.History = History;
        this.Notifications = Notifications;
        this.Changelogs = Changelogs;
    }
}

export function State_$reflection() {
    return record_type("Tools.Tool.Details.Types.State", [], State, () => [["Tool", RequestedValue$1_$reflection(ToolDetailDto_$reflection())], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["UserConfiguration", RequestedValue$1_$reflection(UserConfiguration_$reflection())], ["ProblemReports", RequestedValue$1_$reflection(list_type(ProblemReport_$reflection()))], ["UserData", TokenDataDto_$reflection()], ["Customer", Customer_$reflection()], ["ImageViewerOpen", bool_type], ["Manufacturers", list_type(string_type)], ["QuickOverviewDialog", anonRecord_type(["IsLoading", bool_type], ["IsOpen", bool_type])], ["Documents", RequestedValue$1_$reflection(list_type(DocumentDetail_$reflection()))], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["History", RequestedValue$1_$reflection(list_type(ToolHistoryDto_$reflection()))], ["Notifications", RequestedValue$1_$reflection(list_type(OverviewNotification_$reflection()))], ["Changelogs", RequestedValue$1_$reflection(list_type(ChangelogDto_$reflection()))]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateQuickOverviewItems", "SetIsQuickOverviewDialogOpen", "FetchTool", "FetchNotifications", "ToolDeleted", "DeleteProblemReport", "ToolFetched", "ProblemReportsFetched", "ManufacturersFetched", "AssignQrCode", "QrCodeAssigned", "DeleteQRCodes", "QRCodeDeleteCompleted", "SaveNotice", "NoticeSaved", "UpdateNotice", "NoticeUpdated", "UploadImage", "DeleteImage", "ImageDeleted", "ImageSaved", "BlobReceived", "UserConfigurationUpdated", "UserConfigurationFetched", "FetchDocuments", "DownloadFile", "DocumentDownloaded", "ProblemReportConfirmed", "ProblemReportDeleteResponse", "DocumentsFetched", "CustomerConfigurationFetched", "StoragesFetched", "HistoryFetched", "NotificationsFetched", "FetchChangelogs", "ChangelogsFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Tools.Tool.Details.Types.Msg", [], Msg, () => [[["Item", list_type(string_type)]], [], [], [], [], [["Item", ProblemReport_$reflection()]], [["Item", ToolDetailDto_$reflection()]], [["Item", list_type(ProblemReport_$reflection())]], [["Item", list_type(string_type)]], [["Item", string_type]], [["Item", PostResponse$1_$reflection(AssignQRCodeResult_$reflection())]], [["Item", list_type(string_type)]], [["Item", PostResponse$1_$reflection(DeleteQRCodeResult_$reflection())]], [["Item1", string_type], ["Item2", bool_type]], [["Item", PostResponse$1_$reflection(ToolNoticeResponse_$reflection())]], [["Item", Message_$reflection()]], [["Item", PostResponse$1_$reflection(ToolNoticeResponse_$reflection())]], [["Item", class_type("Browser.Types.File", undefined)]], [], [["Item", PostResponse$1_$reflection(ImageDeletedResponse_$reflection())]], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [["Item1", class_type("Browser.Types.Blob", undefined)], ["Item2", string_type]], [["Item", EmptyResponse_$reflection()]], [["Item", UserConfiguration_$reflection()]], [], [["Item1", DocumentId_$reflection()], ["fileName", string_type]], [["Item1", class_type("Fetch.Types.Response")], ["Item2", string_type]], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", list_type(DocumentDetail_$reflection())]], [["Item", CustomerConfiguration_$reflection()]], [["Item", list_type(Storage_$reflection())]], [["Item", list_type(ToolHistoryDto_$reflection())]], [["Item", list_type(OverviewNotification_$reflection())]], [], [["Item", list_type(ChangelogDto_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

