import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, list_type, lambda_type, unit_type, union_type, class_type, bool_type, float64_type, option_type, anonRecord_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { FormState__getValidation, StorageId__get_unwrap, FormState__setValidationResponse_5219762A, RequestedValue$1, UpdateConsumableStockDto, FormState_get_empty, RequestedValue$1_$reflection, UpdateConsumableStockDto_$reflection, FormState_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { Consumable_$reflection } from "../../Shared/Consumable.js";
import { putStockChangeCmd, getConsumable } from "../../Requests/Consumable.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { singleton, tryHead, contains, filter, map, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { unwrapStorageId } from "../../Shared/Helper.js";
import { createObj, safeHash, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { unwrap, value as value_6, map as map_1 } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Select } from "../../Components/Select.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { WarningAlert } from "../../Components/Alert.js";
import { NumberInput } from "../../Components/Input.js";
import { TextArea } from "../../Components/TextArea.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSelectedStorage", "SetComment", "SetQuantity", "SetIsStockTrackingEnabled", "UpdateStockResultResponse", "UpdateStock", "ConsumableFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Consumables.StockAddDialog.Msg", [], Msg, () => [[["Item", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))]], [["Item", string_type]], [["Item", option_type(float64_type)]], [["Item", bool_type]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", Consumable_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(FormState, SuccessCallback, OnClose, Dto, Consumable, Quantity, Storages, SelectedStorage) {
        super();
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Dto = Dto;
        this.Consumable = Consumable;
        this.Quantity = Quantity;
        this.Storages = Storages;
        this.SelectedStorage = SelectedStorage;
    }
}

function State_$reflection() {
    return record_type("Consumables.StockAddDialog.State", [], State, () => [["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Dto", UpdateConsumableStockDto_$reflection()], ["Consumable", RequestedValue$1_$reflection(Consumable_$reflection())], ["Quantity", option_type(float64_type)], ["Storages", list_type(Storage_$reflection())], ["SelectedStorage", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))]]);
}

function init(props) {
    return [new State(FormState_get_empty(), props.SuccessCallback, props.OnClose, new UpdateConsumableStockDto("", undefined, "", true), new RequestedValue$1(0, []), undefined, props.Storages, undefined), getConsumable((Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(7, [Item_1])), props.ConsumableId)];
}

function update(msg, state) {
    let bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4, bind$0040;
    switch (msg.tag) {
        case 1:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, (bind$0040_1 = state.Dto, new UpdateConsumableStockDto(bind$0040_1.StorageId, bind$0040_1.Quantity, msg.fields[0], bind$0040_1.IsStockTrackingEnabled)), state.Consumable, state.Quantity, state.Storages, state.SelectedStorage), Cmd_none()];
        case 2:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, (bind$0040_2 = state.Dto, new UpdateConsumableStockDto(bind$0040_2.StorageId, msg.fields[0], bind$0040_2.Comment, bind$0040_2.IsStockTrackingEnabled)), state.Consumable, state.Quantity, state.Storages, state.SelectedStorage), Cmd_none()];
        case 3:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, (bind$0040_3 = state.Dto, new UpdateConsumableStockDto(bind$0040_3.StorageId, bind$0040_3.Quantity, bind$0040_3.Comment, msg.fields[0])), state.Consumable, state.Quantity, state.Storages, state.SelectedStorage), Cmd_none()];
        case 4: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(FormState__setValidationResponse_5219762A(state.FormState, response.fields[0]), state.SuccessCallback, state.OnClose, state.Dto, state.Consumable, state.Quantity, state.Storages, state.SelectedStorage), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(FormState_get_empty(), state.SuccessCallback, state.OnClose, state.Dto, state.Consumable, state.Quantity, state.Storages, state.SelectedStorage), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
        case 5: {
            const matchValue = state.SelectedStorage;
            const matchValue_1 = state.Consumable;
            let matchResult, consumable, storage;
            if (matchValue == null) {
                if (matchValue_1.tag === 0) {
                    matchResult = 1;
                }
                else {
                    matchResult = 1;
                }
            }
            else if (matchValue_1.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                consumable = matchValue_1.fields[0];
                storage = matchValue;
            }
            switch (matchResult) {
                case 0:
                    return [state, putStockChangeCmd(consumable.Id, (bind$0040_4 = state.Dto, new UpdateConsumableStockDto(unwrapStorageId(storage.value.Id), bind$0040_4.Quantity, bind$0040_4.Comment, bind$0040_4.IsStockTrackingEnabled)), (Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(7, [Item_1])))];
                default:
                    return [state, Cmd_none()];
            }
        }
        case 6: {
            const response_1 = msg.fields[0];
            const stockPerStorageStorageIds = map((sps) => sps.StorageId, response_1.StockPerStorage);
            const storages = filter((storage_1) => !contains(storage_1.Id, stockPerStorageStorageIds, {
                Equals: equals,
                GetHashCode: safeHash,
            }), state.Storages);
            const selectedStorage = map_1((storage_2) => ({
                label: storage_2.Name,
                value: storage_2,
            }), tryHead(storages));
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Dto, new RequestedValue$1(1, [response_1]), state.Quantity, storages, state.SelectedStorage), singleton((dispatch) => {
                dispatch(new Msg(0, [selectedStorage]));
            })];
        }
        case 7:
            return [new State(FormState_get_empty(), state.SuccessCallback, state.OnClose, state.Dto, state.Consumable, state.Quantity, state.Storages, state.SelectedStorage), Cmd_none()];
        default: {
            const value = msg.fields[0];
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, (bind$0040 = state.Dto, new UpdateConsumableStockDto(StorageId__get_unwrap(value_6(value).value.Id), bind$0040.Quantity, bind$0040.Comment, bind$0040.IsStockTrackingEnabled)), state.Consumable, state.Quantity, state.Storages, value), Cmd_none()];
        }
    }
}

function DialogBody(props) {
    let matchValue, storage, elems;
    const t = useTranslation()[0];
    const storages = map((storage_1) => ({
        label: storage_1.Name,
        value: storage_1,
    }), (matchValue = props.SelectedStorage, (matchValue == null) ? props.Storages : ((storage = matchValue, filter((s) => !equals(s.Id, storage.value.Id), props.Storages)))));
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = toList(delay(() => append(singleton_1(createElement(Select, {
        ComponentState: props.ComponentState,
        IsClearable: false,
        Label: t("tool.switch_storage_target"),
        NoOptionsMessage: "",
        OnChange: props.SetSelectedStorage,
        Options: storages,
        PlaceholderKey: "",
        TestId: "",
        Value: unwrap(props.SelectedStorage),
    })), delay(() => append(singleton_1(Checkbox({
        ComponentState: props.ComponentState,
        IsChecked: props.IsStockTrackingEnabled,
        Label: t("consumable.dialogs.stock_change_dialog.stock_tracking_enabled_description"),
        OnCheck: props.SetIsStockTrackingEnabled,
        TestId: "",
    })), delay(() => append(!props.IsStockTrackingEnabled ? singleton_1(createElement(WarningAlert, {
        Label: t("consumable.dialogs.stock_change_dialog.stock_tracking_enabled_information"),
    })) : empty(), delay(() => append(props.IsStockTrackingEnabled ? singleton_1(createElement(NumberInput, {
        ComponentState: props.IsStockTrackingEnabled ? props.ComponentState : "disabled",
        Label: t("general.quantity_3"),
        OnChange: props.SetQuantity,
        TestId: "",
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "quantity")),
        Value: unwrap(props.Quantity),
    })) : empty(), delay(() => singleton_1(createElement(TextArea, {
        ComponentState: props.ComponentState,
        Label: t("general.comment"),
        OnChange: props.SetComment,
        TestId: "",
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "comment")),
        Value: props.Comment,
    })))))))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function StockAddDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        ConsumableId: props.ConsumableId,
        OnClose: props.OnClose,
        Storages: props.Storages,
        SuccessCallback: props.SuccessCallback,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const componentState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("consumable.dialogs.stock_add_dialog.title"), {
        Body: toList(delay(() => ((state_1.Consumable.tag === 0) ? singleton_1(createElement(Skeleton, {
            Variant: "normal",
        })) : singleton_1(createElement(DialogBody, {
            Comment: state_1.Dto.Comment,
            ComponentState: componentState,
            FormState: state_1.FormState,
            IsStockTrackingEnabled: state_1.Dto.IsStockTrackingEnabled,
            Quantity: unwrap(state_1.Quantity),
            SelectedStorage: unwrap(state_1.SelectedStorage),
            SetComment: (value) => {
                dispatch(new Msg(1, [value]));
            },
            SetIsStockTrackingEnabled: (value_1) => {
                dispatch(new Msg(3, [value_1]));
            },
            SetQuantity: (value_2) => {
                dispatch(new Msg(2, [value_2]));
            },
            SetSelectedStorage: (value_3) => {
                dispatch(new Msg(0, [value_3]));
            },
            Storages: state_1.Storages,
        }))))),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-stock-add-dialog-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(5, []));
            },
            TestId: "save-stock-add-dialog-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

