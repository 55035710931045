import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { LocationId_$reflection, StorageId_$reflection } from "./Shared.js";
import { union_type, record_type, class_type, bool_type, option_type, list_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class Storage extends Record {
    constructor(Id, Name, Description, QRCodeId, QRCodeIds, LocationId, AssignedUserIds, AccessByUser, IsDeleted, IsSelfService, CreatedAt, UpdatedAt) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.QRCodeId = QRCodeId;
        this.QRCodeIds = QRCodeIds;
        this.LocationId = LocationId;
        this.AssignedUserIds = AssignedUserIds;
        this.AccessByUser = AccessByUser;
        this.IsDeleted = IsDeleted;
        this.IsSelfService = IsSelfService;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function Storage_$reflection() {
    return record_type("Shared.Storage.Storage", [], Storage, () => [["Id", StorageId_$reflection()], ["Name", string_type], ["Description", string_type], ["QRCodeId", string_type], ["QRCodeIds", list_type(string_type)], ["LocationId", option_type(LocationId_$reflection())], ["AssignedUserIds", list_type(string_type)], ["AccessByUser", list_type(string_type)], ["IsDeleted", bool_type], ["IsSelfService", bool_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export class StorageCreateResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Created", "CreateError"];
    }
}

export function StorageCreateResponse_$reflection() {
    return union_type("Shared.Storage.StorageCreateResponse", [], StorageCreateResponse, () => [[["Item", Storage_$reflection()]], []]);
}

export class StorageUpdateResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Updated", "UpdateError"];
    }
}

export function StorageUpdateResponse_$reflection() {
    return union_type("Shared.Storage.StorageUpdateResponse", [], StorageUpdateResponse, () => [[["Item", Storage_$reflection()]], []]);
}

export class StorageDeleteResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["StorageNotEmpty", "Deleted"];
    }
}

export function StorageDeleteResponse_$reflection() {
    return union_type("Shared.Storage.StorageDeleteResponse", [], StorageDeleteResponse, () => [[], []]);
}

