import { FormState__setValidationResponse_5219762A, SSOConfiguration as SSOConfiguration_5, FormState_get_empty, RequestedValue$1 } from "../../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { updateSSOConfiguration, getCustomerConfiguration } from "../../../Requests/Configuration.js";
import { ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { Cmd_successToast, Cmd_errorToast } from "../../../Components/Toast.js";

export const initialState = new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), FormState_get_empty());

export function init() {
    return [initialState, Cmd_batch(singleton(getCustomerConfiguration((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(8, [Item_1])))))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const matchValue_1 = state.SSOConfiguration;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const ssoConfiguration_1 = matchValue_1.fields[0];
                return [new State(state.CustomerConfiguration, new RequestedValue$1(1, [new SSOConfiguration_5(ssoConfiguration_1.SSOActive, msg.fields[0], ssoConfiguration_1.ClientSecret, ssoConfiguration_1.OpenIdConnectDiscoveryUrl, ssoConfiguration_1.AllowedHosts)]), state.FormState), Cmd_none()];
            }
        }
        case 2: {
            const matchValue_2 = state.SSOConfiguration;
            if (matchValue_2.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const ssoConfiguration_2 = matchValue_2.fields[0];
                return [new State(state.CustomerConfiguration, new RequestedValue$1(1, [new SSOConfiguration_5(ssoConfiguration_2.SSOActive, ssoConfiguration_2.ClientId, msg.fields[0], ssoConfiguration_2.OpenIdConnectDiscoveryUrl, ssoConfiguration_2.AllowedHosts)]), state.FormState), Cmd_none()];
            }
        }
        case 3: {
            const matchValue_3 = state.SSOConfiguration;
            if (matchValue_3.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const ssoConfiguration_3 = matchValue_3.fields[0];
                return [new State(state.CustomerConfiguration, new RequestedValue$1(1, [new SSOConfiguration_5(ssoConfiguration_3.SSOActive, ssoConfiguration_3.ClientId, ssoConfiguration_3.ClientSecret, msg.fields[0], ssoConfiguration_3.AllowedHosts)]), state.FormState), Cmd_none()];
            }
        }
        case 4: {
            const matchValue_4 = state.SSOConfiguration;
            if (matchValue_4.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const ssoConfiguration_4 = matchValue_4.fields[0];
                return [new State(state.CustomerConfiguration, new RequestedValue$1(1, [new SSOConfiguration_5(ssoConfiguration_4.SSOActive, ssoConfiguration_4.ClientId, ssoConfiguration_4.ClientSecret, ssoConfiguration_4.OpenIdConnectDiscoveryUrl, msg.fields[0])]), state.FormState), Cmd_none()];
            }
        }
        case 6: {
            const matchValue_5 = state.SSOConfiguration;
            if (matchValue_5.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, updateSSOConfiguration(matchValue_5.fields[0], (Item_5) => (new Msg(7, [Item_5])), (Item_6) => (new Msg(8, [Item_6])))];
            }
        }
        case 5: {
            const response = msg.fields[0];
            return [new State(new RequestedValue$1(1, [response]), new RequestedValue$1(1, [response.SSOConfiguration]), state.FormState), Cmd_none()];
        }
        case 7: {
            const response_1 = msg.fields[0];
            if (response_1.tag === 1) {
                return [new State(state.CustomerConfiguration, state.SSOConfiguration, FormState__setValidationResponse_5219762A(state.FormState, response_1.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.CustomerConfiguration, state.SSOConfiguration, FormState_get_empty()), Cmd_batch(ofArray([Cmd_successToast("settings.permissions_changed_successful"), getCustomerConfiguration((Item_7) => (new Msg(5, [Item_7])), (Item_8) => (new Msg(8, [Item_8])))]))];
            }
        }
        case 8:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.SSOConfiguration;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const ssoConfiguration = matchValue.fields[0];
                return [new State(state.CustomerConfiguration, new RequestedValue$1(1, [new SSOConfiguration_5(msg.fields[0], ssoConfiguration.ClientId, ssoConfiguration.ClientSecret, ssoConfiguration.OpenIdConnectDiscoveryUrl, ssoConfiguration.AllowedHosts)]), state.FormState), Cmd_none()];
            }
        }
    }
}

