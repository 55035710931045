import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__setValidationResponse_5219762A, FormState__startLoading, FormState_get_empty, FormState_$reflection, ConsumableId_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { deleteConsumable } from "../../Requests/Consumable.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ConfirmationDialog } from "../../Components/Dialog.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DeleteConsumableResponse", "DeleteConsumable", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Consumables.ConsumableDeleteDialog.Msg", [], Msg, () => [[["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(ConsumableId, FormState, SuccessCallback, OnClose) {
        super();
        this.ConsumableId = ConsumableId;
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
    }
}

function State_$reflection() {
    return record_type("Consumables.ConsumableDeleteDialog.State", [], State, () => [["ConsumableId", ConsumableId_$reflection()], ["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)]]);
}

function init(consumableId, successCallback, onClose) {
    return [new State(consumableId, FormState_get_empty(), successCallback, onClose), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.ConsumableId, FormState__startLoading(state.FormState), state.SuccessCallback, state.OnClose), deleteConsumable(state.ConsumableId, (Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(2, [Item_1])))];
        case 2:
            return [new State(state.ConsumableId, FormState_get_empty(), state.SuccessCallback, state.OnClose), Cmd_none()];
        default: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.ConsumableId, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0]), state.SuccessCallback, state.OnClose), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.ConsumableId, FormState__startLoading(state.FormState), state.SuccessCallback, state.OnClose), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast("consumable.dialog_delete_success")]))];
            }
        }
    }
}

export function ConsumableDeleteDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.ConsumableId, props.SuccessCallback, props.OnClose);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    return createElement(ConfirmationDialog, (Title = t("general.delete"), {
        Description: t("consumable.delete_consumable_question"),
        FormState: patternInput_1[0].FormState,
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        OnConfirm: () => {
            patternInput_1[1](new Msg(1, []));
        },
        Title: Title,
    }));
}

