import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { DocumentId_$reflection, ToolId_$reflection, EmptyResponse_$reflection, ImageDeletedResponse_$reflection, PostResponse$1_$reflection, StorageId_$reflection, DocumentDetail_$reflection, Customer_$reflection, TokenDataDto_$reflection, RequestedValue$1_$reflection, ReminderId_$reflection, ToolReservationId_$reflection } from "../../../Shared/Shared.js";
import { anonRecord_type, bool_type, list_type, record_type, int32_type, option_type, class_type, string_type, union_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { DeleteQRCodeResult_$reflection, AssignQRCodeResult_$reflection, ToolWithQuantityHistoryDto_$reflection, ToolWithQuantityDetailDto_$reflection } from "../../../Shared/Tool.js";
import { Storage_$reflection } from "../../../Shared/Storage.js";
import { Requests_ReceiverNameDto_$reflection, UserConfiguration_$reflection } from "../../../Shared/User.js";
import { OverviewNotification_$reflection } from "../../../Shared/Notification.js";
import { ChangelogDto_$reflection } from "../../../Shared/Changelog.js";

export class PlanningTypeId extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ReservationId", "ReminderId"];
    }
}

export function PlanningTypeId_$reflection() {
    return union_type("Tools.ToolWithQuantity.Details.Types.PlanningTypeId", [], PlanningTypeId, () => [[["Item", ToolReservationId_$reflection()]], [["Item", ReminderId_$reflection()]]]);
}

export class PlanningRow extends Record {
    constructor(Type, StartDate, EndDate, ReservationName, ReceiverName, Description, Quantity, Storage, PlanningTypeId) {
        super();
        this.Type = Type;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.ReservationName = ReservationName;
        this.ReceiverName = ReceiverName;
        this.Description = Description;
        this.Quantity = Quantity;
        this.Storage = Storage;
        this.PlanningTypeId = PlanningTypeId;
    }
}

export function PlanningRow_$reflection() {
    return record_type("Tools.ToolWithQuantity.Details.Types.PlanningRow", [], PlanningRow, () => [["Type", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", option_type(class_type("System.DateTime"))], ["ReservationName", string_type], ["ReceiverName", string_type], ["Description", string_type], ["Quantity", option_type(int32_type)], ["Storage", string_type], ["PlanningTypeId", PlanningTypeId_$reflection()]]);
}

export class State extends Record {
    constructor(Tool, Storages, History, UserData, Customer, ImageViewerOpen, UserConfiguration, QuickOverviewDialog, Documents, Receivers, Notifications, Changelogs) {
        super();
        this.Tool = Tool;
        this.Storages = Storages;
        this.History = History;
        this.UserData = UserData;
        this.Customer = Customer;
        this.ImageViewerOpen = ImageViewerOpen;
        this.UserConfiguration = UserConfiguration;
        this.QuickOverviewDialog = QuickOverviewDialog;
        this.Documents = Documents;
        this.Receivers = Receivers;
        this.Notifications = Notifications;
        this.Changelogs = Changelogs;
    }
}

export function State_$reflection() {
    return record_type("Tools.ToolWithQuantity.Details.Types.State", [], State, () => [["Tool", RequestedValue$1_$reflection(ToolWithQuantityDetailDto_$reflection())], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["History", RequestedValue$1_$reflection(list_type(ToolWithQuantityHistoryDto_$reflection()))], ["UserData", TokenDataDto_$reflection()], ["Customer", Customer_$reflection()], ["ImageViewerOpen", bool_type], ["UserConfiguration", RequestedValue$1_$reflection(UserConfiguration_$reflection())], ["QuickOverviewDialog", anonRecord_type(["IsLoading", bool_type], ["IsOpen", bool_type])], ["Documents", RequestedValue$1_$reflection(list_type(DocumentDetail_$reflection()))], ["Receivers", RequestedValue$1_$reflection(list_type(Requests_ReceiverNameDto_$reflection()))], ["Notifications", RequestedValue$1_$reflection(list_type(OverviewNotification_$reflection()))], ["Changelogs", RequestedValue$1_$reflection(list_type(ChangelogDto_$reflection()))]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateQuickOverviewItems", "SetIsQuickOverviewDialogOpen", "OpenImageViewer", "CloseImageViewer", "ToolFetched", "HistoryFetched", "FetchError", "StoragesFetched", "BlobReceived", "FetchTool", "UploadImage", "DeleteImage", "ToolDeleted", "ReceiverNamesFetched", "AssignQrCode", "QrCodeAssigned", "DeleteQRCodes", "QRCodeDeleteCompleted", "ImageDeleted", "ImageSaved", "DownloadDocument", "DocumentFetched", "FetchDocuments", "DocumentsFetched", "DownloadFile", "DocumentDownloaded", "UserConfigurationUpdated", "UserConfigurationFetched", "FetchNotifications", "NotificationsFetched", "FetchChangelogs", "ChangelogsFetched"];
    }
}

export function Msg_$reflection() {
    return union_type("Tools.ToolWithQuantity.Details.Types.Msg", [], Msg, () => [[["Item", list_type(string_type)]], [], [], [], [["Item", ToolWithQuantityDetailDto_$reflection()]], [["Item", list_type(ToolWithQuantityHistoryDto_$reflection())]], [["Item", class_type("System.Exception")]], [["Item", list_type(Storage_$reflection())]], [["Item1", class_type("Browser.Types.Blob", undefined)], ["Item2", string_type]], [], [["Item", class_type("Browser.Types.File", undefined)]], [], [], [["Item", list_type(Requests_ReceiverNameDto_$reflection())]], [["Item1", string_type], ["Item2", StorageId_$reflection()]], [["Item", PostResponse$1_$reflection(AssignQRCodeResult_$reflection())]], [["Item1", string_type], ["Item2", StorageId_$reflection()]], [["Item", PostResponse$1_$reflection(DeleteQRCodeResult_$reflection())]], [["Item", PostResponse$1_$reflection(ImageDeletedResponse_$reflection())]], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [["Item1", ToolId_$reflection()], ["Item2", DocumentId_$reflection()], ["Item3", string_type]], [["Item1", class_type("Fetch.Types.Response")], ["Item2", string_type]], [], [["Item", list_type(DocumentDetail_$reflection())]], [["Item1", DocumentId_$reflection()], ["fileName", string_type]], [["Item1", class_type("Fetch.Types.Response")], ["Item2", string_type]], [["Item", EmptyResponse_$reflection()]], [["Item", UserConfiguration_$reflection()]], [], [["Item", list_type(OverviewNotification_$reflection())]], [], [["Item", list_type(ChangelogDto_$reflection())]]]);
}

