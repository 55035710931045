import { CustomerConfiguration, RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { updateCustomerConfiguration, getCustomerConfiguration } from "../../Requests/Configuration.js";
import { ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";

export const initialState = new State(new RequestedValue$1(0, []), undefined);

export function init() {
    return [initialState, Cmd_batch(singleton(getCustomerConfiguration((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(7, [Item_1])))))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const matchValue_1 = state.CustomerConfiguration;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const configuration_1 = matchValue_1.fields[0];
                return [new State(new RequestedValue$1(1, [new CustomerConfiguration(configuration_1.Staging, configuration_1.IsGlobalAppSearchActiveForUserRole, configuration_1.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, configuration_1.IsSelfServiceReservationForUserActive, configuration_1.ReceiverVisibilityConfiguration, configuration_1.NotificationEmailsOnlyToAdministrators, configuration_1.DemoDataImported, configuration_1.CanUserSeeAllTools, configuration_1.CanUserWriteNotices, msg.fields[0], configuration_1.GlobalSelfServiceStorageActive, configuration_1.CustomI18nextNamespace, configuration_1.Tenants, configuration_1.TenantName, configuration_1.ReceiverCreateReservationAllowedConfiguration, configuration_1.AllowUserToUserAssignmentViaUserSelection, configuration_1.IsReleaseDateEnabled, configuration_1.ToolManagerCanCreateReceiver, configuration_1.SSOConfiguration)]), state.Message), singleton((dispatch_1) => {
                    dispatch_1(new Msg(4, []));
                })];
            }
        }
        case 2: {
            const matchValue_2 = state.CustomerConfiguration;
            if (matchValue_2.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const configuration_2 = matchValue_2.fields[0];
                return [new State(new RequestedValue$1(1, [new CustomerConfiguration(configuration_2.Staging, configuration_2.IsGlobalAppSearchActiveForUserRole, configuration_2.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, configuration_2.IsSelfServiceReservationForUserActive, configuration_2.ReceiverVisibilityConfiguration, configuration_2.NotificationEmailsOnlyToAdministrators, configuration_2.DemoDataImported, configuration_2.CanUserSeeAllTools, configuration_2.CanUserWriteNotices, configuration_2.AppVisibilityPermissionReceiver, configuration_2.GlobalSelfServiceStorageActive, configuration_2.CustomI18nextNamespace, configuration_2.Tenants, configuration_2.TenantName, msg.fields[0], configuration_2.AllowUserToUserAssignmentViaUserSelection, configuration_2.IsReleaseDateEnabled, configuration_2.ToolManagerCanCreateReceiver, configuration_2.SSOConfiguration)]), state.Message), singleton((dispatch_2) => {
                    dispatch_2(new Msg(4, []));
                })];
            }
        }
        case 3: {
            const matchValue_3 = state.CustomerConfiguration;
            if (matchValue_3.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const configuration_3 = matchValue_3.fields[0];
                return [new State(new RequestedValue$1(1, [new CustomerConfiguration(configuration_3.Staging, configuration_3.IsGlobalAppSearchActiveForUserRole, configuration_3.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, configuration_3.IsSelfServiceReservationForUserActive, configuration_3.ReceiverVisibilityConfiguration, configuration_3.NotificationEmailsOnlyToAdministrators, configuration_3.DemoDataImported, configuration_3.CanUserSeeAllTools, configuration_3.CanUserWriteNotices, configuration_3.AppVisibilityPermissionReceiver, configuration_3.GlobalSelfServiceStorageActive, configuration_3.CustomI18nextNamespace, configuration_3.Tenants, configuration_3.TenantName, configuration_3.ReceiverCreateReservationAllowedConfiguration, configuration_3.AllowUserToUserAssignmentViaUserSelection, configuration_3.IsReleaseDateEnabled, msg.fields[0], configuration_3.SSOConfiguration)]), state.Message), singleton((dispatch_3) => {
                    dispatch_3(new Msg(4, []));
                })];
            }
        }
        case 4: {
            const matchValue_4 = state.CustomerConfiguration;
            if (matchValue_4.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, updateCustomerConfiguration(matchValue_4.fields[0], (Item_4) => (new Msg(6, [Item_4])), (Item_5) => (new Msg(7, [Item_5])))];
            }
        }
        case 5:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.Message), Cmd_none()];
        case 6:
            return [state, (msg.fields[0].Result.tag === 1) ? Cmd_batch(ofArray([Cmd_errorToast("settings.receiver_failed_deactivate"), getCustomerConfiguration((Item_8) => (new Msg(5, [Item_8])), (Item_9) => (new Msg(7, [Item_9])))])) : Cmd_batch(ofArray([Cmd_successToast("settings.permissions_changed_successful"), getCustomerConfiguration((Item_6) => (new Msg(5, [Item_6])), (Item_7) => (new Msg(7, [Item_7])))]))];
        case 7:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.CustomerConfiguration;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const configuration = matchValue.fields[0];
                return [new State(new RequestedValue$1(1, [new CustomerConfiguration(configuration.Staging, configuration.IsGlobalAppSearchActiveForUserRole, configuration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, configuration.IsSelfServiceReservationForUserActive, msg.fields[0], configuration.NotificationEmailsOnlyToAdministrators, configuration.DemoDataImported, configuration.CanUserSeeAllTools, configuration.CanUserWriteNotices, configuration.AppVisibilityPermissionReceiver, configuration.GlobalSelfServiceStorageActive, configuration.CustomI18nextNamespace, configuration.Tenants, configuration.TenantName, configuration.ReceiverCreateReservationAllowedConfiguration, configuration.AllowUserToUserAssignmentViaUserSelection, configuration.IsReleaseDateEnabled, configuration.ToolManagerCanCreateReceiver, configuration.SSOConfiguration)]), state.Message), singleton((dispatch) => {
                    dispatch(new Msg(4, []));
                })];
            }
        }
    }
}

