import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { createObj, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Msg } from "./Types.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { BoxHeader } from "../../Components/Text.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { CustomerConfiguration } from "../../Shared/Shared.js";
import { InformationAlert } from "../../Components/Alert.js";
import { Feature, isFeatureEnabled } from "../../FeatureFlip.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { Skeleton } from "../../Components/Skeleton.js";

function Permissions(props) {
    let elems_9, elems_1, elems, elems_4, elems_2, elems_3, elems_8, elems_5, elems_6, elems_7;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(props.CustomerConfiguration);
    const setCustomerConfiguration = patternInput_1[1];
    const customerConfiguration = patternInput_1[0];
    const dependencies = [customerConfiguration];
    reactApi.useEffect(() => {
        if (!equals(props.CustomerConfiguration, customerConfiguration)) {
            props.Dispatch(new Msg(0, [customerConfiguration]));
        }
    }, dependencies);
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_9 = [createElement(BoxHeader, {
        Text: "System",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-5"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems = toList(delay(() => append(singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.NotificationEmailsOnlyToAdministrators,
        Label: t("settings.permissions_notification_emails_only_to_administrators"),
        OnCheck: (value_3) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, value_3, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection, customerConfiguration.IsReleaseDateEnabled, customerConfiguration.ToolManagerCanCreateReceiver, customerConfiguration.SSOConfiguration));
        },
        TestId: "",
    })), delay(() => append(singleton(createElement(InformationAlert, {
        Label: t("settings.permissions_notification_emails_only_to_administrators_info"),
    })), delay(() => append(singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.CanUserWriteNotices,
        Label: t("settings.permissions_can_write_notices"),
        OnCheck: (value_4) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, value_4, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection, customerConfiguration.IsReleaseDateEnabled, customerConfiguration.ToolManagerCanCreateReceiver, customerConfiguration.SSOConfiguration));
        },
        TestId: "",
    })), delay(() => append(singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.CanUserSeeAllTools,
        Label: t("settings.permissions_user_can_see_all_tools"),
        OnCheck: (value_5) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, value_5, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection, customerConfiguration.IsReleaseDateEnabled, customerConfiguration.ToolManagerCanCreateReceiver, customerConfiguration.SSOConfiguration));
        },
        TestId: "",
    })), delay(() => (isFeatureEnabled(new Feature(17, [])) ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.IsReleaseDateEnabled,
        Label: t("settings.permissions_release_date_enabled"),
        OnCheck: (value_6) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection, value_6, customerConfiguration.ToolManagerCanCreateReceiver, customerConfiguration.SSOConfiguration));
        },
        TestId: "",
    })) : empty()))))))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement(BoxHeader, {
        Text: t("general.storage"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-5"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_2 = [Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.GlobalSelfServiceStorageActive,
        Label: t("settings.global_self_service_storage"),
        OnCheck: (value_11) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, value_11, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection, customerConfiguration.IsReleaseDateEnabled, customerConfiguration.ToolManagerCanCreateReceiver, customerConfiguration.SSOConfiguration));
        },
        TestId: "",
    }), createElement(InformationAlert, {
        Label: t("settings.global_self_service_storage_info"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_3 = [Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification,
        Label: t("settings.permissions_self_service_user_return_tool"),
        OnCheck: (value_14) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, value_14, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection, customerConfiguration.IsReleaseDateEnabled, customerConfiguration.ToolManagerCanCreateReceiver, customerConfiguration.SSOConfiguration));
        },
        TestId: "",
    }), createElement(InformationAlert, {
        Label: t("settings.permissions_self_service_user_return_tool_info"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_8 = [createElement(BoxHeader, {
        Text: "App",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_5 = [Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.IsGlobalAppSearchActiveForUserRole,
        Label: t("settings.permissions_activate_global_search_for_user"),
        OnCheck: (value_19) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, value_19, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection, customerConfiguration.IsReleaseDateEnabled, customerConfiguration.ToolManagerCanCreateReceiver, customerConfiguration.SSOConfiguration));
        },
        TestId: "",
    }), createElement(InformationAlert, {
        Label: t("settings.permissions_activate_global_search_for_user_info"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_6 = [Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.IsSelfServiceReservationForUserActive,
        Label: t("settings.permissions_self_service_user_reservation"),
        OnCheck: (value_22) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, value_22, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection, customerConfiguration.IsReleaseDateEnabled, customerConfiguration.ToolManagerCanCreateReceiver, customerConfiguration.SSOConfiguration));
        },
        TestId: "",
    }), createElement(InformationAlert, {
        Label: t("settings.permissions_self_service_user_reservation_info"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_7 = [Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.AllowUserToUserAssignmentViaUserSelection,
        Label: t("settings.permissions_user_to_user_assignment_allowed_via_user_selection"),
        OnCheck: (value_25) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, value_25, customerConfiguration.IsReleaseDateEnabled, customerConfiguration.ToolManagerCanCreateReceiver, customerConfiguration.SSOConfiguration));
        },
        TestId: "",
    }), createElement(InformationAlert, {
        Label: t("settings.permissions_user_to_user_assignment_allowed_via_user_selection_info"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])));
}

export function PermissionsView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init_1, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const matchValue = patternInput[0].CustomerConfiguration;
    if (matchValue.tag === 0) {
        return createElement(Skeleton, {
            Variant: "normal",
        });
    }
    else {
        return createElement(Permissions, {
            CustomerConfiguration: matchValue.fields[0],
            Dispatch: patternInput[1],
        });
    }
}

