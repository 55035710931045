import { PostResponse$1_$reflection, RequestedValue$1, StorageId } from "../Shared/Shared.js";
import { filter, ofArray, singleton, empty } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { utcNow } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { StorageDeleteResponse_$reflection, StorageUpdateResponse_$reflection, StorageCreateResponse_$reflection, Storage_$reflection, Storage } from "../Shared/Storage.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../Communication.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { list_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Location_$reflection } from "../Shared/Location.js";
import { getCustomerConfiguration } from "../Requests/Configuration.js";
import { User_$reflection } from "../Shared/User.js";
import { unwrapStorageId } from "../Shared/Helper.js";
import { map } from "../fable_modules/fable-library-js.4.19.2/Array.js";
import { ofNullable, defaultArg } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../Components/Toast.js";
import { unwrapStorageId as unwrapStorageId_1 } from "../Shared/Helper.js";
import { Cmd_ofEffect } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";

export const emptyStorage = new Storage(new StorageId("00000000-0000-0000-0000-000000000000"), "", "", "", empty(), undefined, empty(), empty(), false, false, utcNow(), utcNow());

export function init() {
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), false), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item) => (new Msg(13, [Item])), (Item_1) => (new Msg(18, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Location_$reflection())));
    })))), "/api/locations", (Item_2) => (new Msg(11, [Item_2])), (Item_3) => (new Msg(18, [Item_3]))), getCustomerConfiguration((Item_4) => (new Msg(16, [Item_4])), (Item_5) => (new Msg(18, [Item_5])))]))];
}

export function initNewStorageView() {
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(1, [emptyStorage]), false), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(User_$reflection())));
    })))), "/api/receivers/users/active", (Item) => (new Msg(10, [Item])), (Item_1) => (new Msg(18, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Location_$reflection())));
    })))), "/api/locations", (Item_2) => (new Msg(11, [Item_2])), (Item_3) => (new Msg(18, [Item_3]))), getCustomerConfiguration((Item_4) => (new Msg(16, [Item_4])), (Item_5) => (new Msg(18, [Item_5])))]))];
}

export function initViewStorageView(storageId) {
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), false), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Storage_$reflection()));
    })))), `/api/storages/${unwrapStorageId(storageId)}`, (Item) => (new Msg(12, [Item])), (Item_1) => (new Msg(18, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Location_$reflection())));
    })))), "/api/locations", (Item_2) => (new Msg(11, [Item_2])), (Item_3) => (new Msg(18, [Item_3]))), getCustomerConfiguration((Item_4) => (new Msg(16, [Item_4])), (Item_5) => (new Msg(18, [Item_5]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(User_$reflection())));
    })))), "/api/receivers/users/active", (Item_6) => (new Msg(10, [Item_6])), (Item_7) => (new Msg(18, [Item_7])))]))];
}

export function initEditStorageView(storageId) {
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), false), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Storage_$reflection()));
    })))), `/api/storages/${unwrapStorageId(storageId)}`, (Item) => (new Msg(12, [Item])), (Item_1) => (new Msg(18, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(User_$reflection())));
    })))), "/api/receivers/users/active", (Item_2) => (new Msg(10, [Item_2])), (Item_3) => (new Msg(18, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(Location_$reflection())));
    })))), "/api/locations", (Item_4) => (new Msg(11, [Item_4])), (Item_5) => (new Msg(18, [Item_5]))), getCustomerConfiguration((Item_6) => (new Msg(16, [Item_6])), (Item_7) => (new Msg(18, [Item_7])))]))];
}

export function update(msg, state) {
    let xs, xs_2;
    switch (msg.tag) {
        case 1: {
            const matchValue_1 = state.StorageForm;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const form_1 = matchValue_1.fields[0];
                return [new State(state.Storages, state.Locations, state.Configuration, state.Users, new RequestedValue$1(1, [new Storage(form_1.Id, form_1.Name, msg.fields[0], form_1.QRCodeId, form_1.QRCodeIds, form_1.LocationId, form_1.AssignedUserIds, form_1.AccessByUser, form_1.IsDeleted, form_1.IsSelfService, form_1.CreatedAt, form_1.UpdatedAt)]), state.IsValidated), Cmd_none()];
            }
        }
        case 3: {
            const location = msg.fields[0];
            const matchValue_2 = state.StorageForm;
            let matchResult, form_2, location_1, form_3;
            if (location == null) {
                if (matchValue_2.tag === 1) {
                    matchResult = 1;
                    form_3 = matchValue_2.fields[0];
                }
                else {
                    matchResult = 2;
                }
            }
            else if (matchValue_2.tag === 1) {
                matchResult = 0;
                form_2 = matchValue_2.fields[0];
                location_1 = location;
            }
            else {
                matchResult = 2;
            }
            switch (matchResult) {
                case 0:
                    return [new State(state.Storages, state.Locations, state.Configuration, state.Users, new RequestedValue$1(1, [new Storage(form_2.Id, form_2.Name, form_2.Description, form_2.QRCodeId, form_2.QRCodeIds, location_1.value.Id, form_2.AssignedUserIds, form_2.AccessByUser, form_2.IsDeleted, form_2.IsSelfService, form_2.CreatedAt, form_2.UpdatedAt)]), state.IsValidated), Cmd_none()];
                case 1:
                    return [new State(state.Storages, state.Locations, state.Configuration, state.Users, new RequestedValue$1(1, [new Storage(form_3.Id, form_3.Name, form_3.Description, form_3.QRCodeId, form_3.QRCodeIds, undefined, form_3.AssignedUserIds, form_3.AccessByUser, form_3.IsDeleted, form_3.IsSelfService, form_3.CreatedAt, form_3.UpdatedAt)]), state.IsValidated), Cmd_none()];
                default:
                    return [state, Cmd_none()];
            }
        }
        case 2: {
            const matchValue_4 = state.StorageForm;
            if (matchValue_4.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const form_4 = matchValue_4.fields[0];
                return [new State(state.Storages, state.Locations, state.Configuration, state.Users, new RequestedValue$1(1, [new Storage(form_4.Id, form_4.Name, form_4.Description, form_4.QRCodeId, form_4.QRCodeIds, form_4.LocationId, form_4.AssignedUserIds, form_4.AccessByUser, form_4.IsDeleted, msg.fields[0], form_4.CreatedAt, form_4.UpdatedAt)]), state.IsValidated), Cmd_none()];
            }
        }
        case 4: {
            const assignedUserIds = ofArray(map((u) => u.value.Id, defaultArg(ofNullable(msg.fields[0]), [])));
            const matchValue_5 = state.StorageForm;
            if (matchValue_5.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const form_5 = matchValue_5.fields[0];
                return [new State(state.Storages, state.Locations, state.Configuration, state.Users, new RequestedValue$1(1, [new Storage(form_5.Id, form_5.Name, form_5.Description, form_5.QRCodeId, form_5.QRCodeIds, form_5.LocationId, assignedUserIds, form_5.AccessByUser, form_5.IsDeleted, form_5.IsSelfService, form_5.CreatedAt, form_5.UpdatedAt)]), state.IsValidated), Cmd_none()];
            }
        }
        case 5: {
            const userIds = ofArray(map((u_1) => u_1.value.Id, defaultArg(ofNullable(msg.fields[0]), [])));
            const matchValue_6 = state.StorageForm;
            if (matchValue_6.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const form_6 = matchValue_6.fields[0];
                return [new State(state.Storages, state.Locations, state.Configuration, state.Users, new RequestedValue$1(1, [new Storage(form_6.Id, form_6.Name, form_6.Description, form_6.QRCodeId, form_6.QRCodeIds, form_6.LocationId, form_6.AssignedUserIds, userIds, form_6.IsDeleted, form_6.IsSelfService, form_6.CreatedAt, form_6.UpdatedAt)]), state.IsValidated), Cmd_none()];
            }
        }
        case 6: {
            const matchValue_7 = state.StorageForm;
            if (matchValue_7.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const form_7 = matchValue_7.fields[0];
                return [new State(state.Storages, state.Locations, state.Configuration, state.Users, new RequestedValue$1(1, [new Storage(form_7.Id, form_7.Name, form_7.Description, form_7.QRCodeId, form_7.QRCodeIds, form_7.LocationId, filter((uId) => (uId !== msg.fields[0]), form_7.AssignedUserIds), form_7.AccessByUser, form_7.IsDeleted, form_7.IsSelfService, form_7.CreatedAt, form_7.UpdatedAt)]), state.IsValidated), Cmd_none()];
            }
        }
        case 7: {
            const matchValue_8 = state.StorageForm;
            if (matchValue_8.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const form_8 = matchValue_8.fields[0];
                if (isNullOrWhiteSpace(form_8.Name)) {
                    return [new State(state.Storages, state.Locations, state.Configuration, state.Users, state.StorageForm, true), Cmd_none()];
                }
                else {
                    return [state, Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                        const pr = response.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(StorageCreateResponse_$reflection())));
                    })))), ["/api/storages", toString(0, Auto_generateBoxedEncoder_437914C6(Storage_$reflection(), undefined, undefined, undefined)(form_8))], (Item) => (new Msg(15, [Item])), (Item_1) => (new Msg(18, [Item_1])))];
                }
            }
        }
        case 8: {
            const matchValue_9 = state.StorageForm;
            if (matchValue_9.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg_1[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                    const pr_1 = response_1.arrayBuffer();
                    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), PostResponse$1_$reflection(StorageUpdateResponse_$reflection())));
                })))), ["/api/storages", toString(0, Auto_generateBoxedEncoder_437914C6(Storage_$reflection(), undefined, undefined, undefined)(matchValue_9.fields[0]))], (Item_2) => (new Msg(14, [Item_2])), (Item_3) => (new Msg(18, [Item_3])))];
            }
        }
        case 9: {
            const matchValue_10 = state.StorageForm;
            if (matchValue_10.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response_2) => {
                    const pr_2 = response_2.arrayBuffer();
                    return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), StorageDeleteResponse_$reflection()));
                })))), `/api/storages/${unwrapStorageId(matchValue_10.fields[0].Id)}`, (Item_4) => (new Msg(17, [Item_4])), (Item_5) => (new Msg(18, [Item_5])))];
            }
        }
        case 15: {
            const matchValue_11 = msg.fields[0].Result;
            if (matchValue_11.tag === 1) {
                return [state, Cmd_errorToast("storage.dialog_create_error")];
            }
            else {
                return [state, Cmd_batch(ofArray([(xs = ["storages", unwrapStorageId_1(matchValue_11.fields[0].Id)], Cmd_ofEffect((_arg) => {
                    RouterModule_nav(ofArray(xs), 1, 1);
                })), Cmd_successToast("storage.dialog_create_success")]))];
            }
        }
        case 14: {
            const matchValue_12 = msg.fields[0].Result;
            if (matchValue_12.tag === 1) {
                return [state, Cmd_successToast("storage.dialog_update_error")];
            }
            else {
                return [state, Cmd_batch(ofArray([(xs_2 = ["storages", unwrapStorageId_1(matchValue_12.fields[0].Id)], Cmd_ofEffect((_arg_1) => {
                    RouterModule_nav(ofArray(xs_2), 1, 1);
                })), Cmd_successToast("storage.dialog_update_success")]))];
            }
        }
        case 10:
            return [new State(state.Storages, state.Locations, state.Configuration, new RequestedValue$1(1, [msg.fields[0]]), state.StorageForm, state.IsValidated), Cmd_none()];
        case 11:
            return [new State(state.Storages, new RequestedValue$1(1, [msg.fields[0]]), state.Configuration, state.Users, state.StorageForm, state.IsValidated), Cmd_none()];
        case 12:
            return [new State(state.Storages, state.Locations, state.Configuration, state.Users, new RequestedValue$1(1, [msg.fields[0]]), state.IsValidated), Cmd_none()];
        case 13:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.Locations, state.Configuration, state.Users, state.StorageForm, state.IsValidated), Cmd_none()];
        case 16:
            return [new State(state.Storages, state.Locations, new RequestedValue$1(1, [msg.fields[0]]), state.Users, state.StorageForm, state.IsValidated), Cmd_none()];
        case 17:
            if (msg.fields[0].tag === 0) {
                return [state, Cmd_batch(singleton(Cmd_errorToast("storage.dialog_delete_error")))];
            }
            else {
                return [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg_2) => {
                    RouterModule_nav(singleton("/storages"), 1, 1);
                }), Cmd_successToast("storage.dialog_delete_success")]))];
            }
        case 18:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.StorageForm;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const form = matchValue.fields[0];
                return [new State(state.Storages, state.Locations, state.Configuration, state.Users, new RequestedValue$1(1, [new Storage(form.Id, msg.fields[0], form.Description, form.QRCodeId, form.QRCodeIds, form.LocationId, form.AssignedUserIds, form.AccessByUser, form.IsDeleted, form.IsSelfService, form.CreatedAt, form.UpdatedAt)]), state.IsValidated), Cmd_none()];
            }
        }
    }
}

