import { Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../CommunicationV2.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { map, reduce, isEmpty, singleton, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { bool_type, list_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { ConsumableReservationFormDto_$reflection, ConsumableReservationResponse_$reflection, ConsumableHistoryDto_$reflection, ConsumableDetailDto_$reflection, Consumable_$reflection, ConsumableDto_$reflection, ConsumableUpdateFormDto_$reflection, ConsumableCreateFormDto_$reflection } from "../Shared/Consumable.js";
import { UpdateConsumableStockDto_$reflection, UpdateConsumableStorageDto_$reflection, ConsumableReservationId__get_unwrap, AssignConsumableWithNotificationDto_$reflection, StorageId__get_unwrap, UnassignConsumableV2Dto_$reflection, AssignConsumableV2Dto_$reflection, EmptyResponse_$reflection, PostResponse$1_$reflection, ConsumableId__get_unwrap } from "../Shared/Shared.js";
import { token as token_2, addPrefix as addPrefix_1, fetchWithDecoder as fetchWithDecoder_1 } from "../Communication.js";
import { unwrapConsumableReservationId, unwrapConsumableId } from "../Shared/Helper.js";
import { ValidationOnGetRequest } from "../ErrorHandling.js";
import { UnassignQRCode_$reflection, DeleteQRCodeResult_$reflection } from "../Shared/Tool.js";
import { ofNullable } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { singleton as singleton_1, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { fetch$ } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { toISOString } from "../Common.js";

export function createConsumable(consumableForm, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), ["/api/consumables", toString(0, Auto_generateBoxedEncoder_437914C6(ConsumableCreateFormDto_$reflection(), undefined, undefined, undefined)(consumableForm))], successMsg, errorMsg);
}

export function updateConsumable(consumableId, consumableForm, successMsg, errorMsg) {
    const body = toString(0, Auto_generateBoxedEncoder_437914C6(ConsumableUpdateFormDto_$reflection(), undefined, undefined, undefined)(consumableForm));
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/consumables/${ConsumableId__get_unwrap(consumableId)}`, body], successMsg, errorMsg);
}

export function getConsumables(successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(ConsumableDto_$reflection())));
    })))), "/api/consumables/overview", successMsg, errorMsg);
}

export function getConsumable(successMsg, errorMsg, consumableId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Consumable_$reflection()));
    })))), `/api/consumables/${unwrapConsumableId(consumableId)}`, successMsg, errorMsg);
}

export function getSelectedConsumables(consumableIds, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Consumable_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), `/api/consumables/selected?ids=${isEmpty(consumableIds) ? "" : reduce((id1, id2) => (`${id1},${id2}`), map(unwrapConsumableId, consumableIds))}`, successMsg, errorMsg);
}

export function getConsumableDetailDto(successMsg, errorMsg, consumableId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), ConsumableDetailDto_$reflection()));
    })))), `/api/consumables/${unwrapConsumableId(consumableId)}/details`, successMsg, errorMsg);
}

export function getConsumableHistoryDto(successMsg, errorMsg, consumableId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(ConsumableHistoryDto_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), `/api/consumables/${unwrapConsumableId(consumableId)}/history`, successMsg, errorMsg);
}

export function deleteQrCodes(successMsg, errorMsg, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(DeleteQRCodeResult_$reflection())));
    })))), ["/api/consumables/unassignqrcode", toString(0, Auto_generateBoxedEncoder_437914C6(UnassignQRCode_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function downloadFileCmd(consumableId, documentId, fileName, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => {
        const body_1 = tupledArg[2];
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const tenant = ofNullable(localStorage.getItem("tenant"));
            const props = (body_1 == null) ? ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue_2;
                return append((matchValue_2 = token_2(undefined), (matchValue_2 == null) ? (empty()) : singleton_1(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2]))), delay(() => append(singleton_1(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_3 = tenant;
                    if (matchValue_3 == null) {
                        return empty();
                    }
                    else {
                        return singleton_1(["X-ONE-Tenant", matchValue_3]);
                    }
                }))));
            })), 0)])]) : ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [body_1]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue;
                return append((matchValue = token_2(undefined), (matchValue == null) ? (empty()) : singleton_1(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue]))), delay(() => append(singleton_1(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_1 = tenant;
                    if (matchValue_1 == null) {
                        return empty();
                    }
                    else {
                        return singleton_1(["X-ONE-Tenant", matchValue_1]);
                    }
                }))));
            })), 0)])]);
            return fetch$(addPrefix_1(tupledArg[0]), props).then((_arg) => (Promise.resolve([_arg, tupledArg[1]])));
        }));
    }, [`/api/consumables/${consumableId.fields[0]}/files/${documentId.fields[0]}`, fileName, undefined], successMsg, errorMsg);
}

export function createConsumableReservation(dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ConsumableReservationResponse_$reflection())));
    })))), [`/api/consumables/${dto.ConsumableId}/reservations`, toString(0, Auto_generateBoxedEncoder_437914C6(ConsumableReservationFormDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function updateConsumableReservation(dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ConsumableReservationResponse_$reflection())));
    })))), [`/api/consumables/${dto.ConsumableId}/reservations`, toString(0, Auto_generateBoxedEncoder_437914C6(ConsumableReservationFormDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function deleteConsumableReservation(consumableId, reservationId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(url), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), `/api/consumables/${unwrapConsumableId(consumableId)}/reservations/${unwrapConsumableReservationId(reservationId)}`, successMsg, errorMsg);
}

export function assignCmd(consumableId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/consumables/${ConsumableId__get_unwrap(consumableId)}/assign`, toString(0, Auto_generateBoxedEncoder_437914C6(AssignConsumableV2Dto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function unassignCmd(consumableId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/consumables/${ConsumableId__get_unwrap(consumableId)}/unassign`, toString(0, Auto_generateBoxedEncoder_437914C6(UnassignConsumableV2Dto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function deleteConsumableStorage(consumableId, storageId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), `/api/consumables/${ConsumableId__get_unwrap(consumableId)}/storage/${StorageId__get_unwrap(storageId)}`, successMsg, errorMsg);
}

export function assignWithNotificationCmd(consumableId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_3, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/consumables/${ConsumableId__get_unwrap(consumableId)}/assign/notify`, toString(0, Auto_generateBoxedEncoder_437914C6(AssignConsumableWithNotificationDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function checkReservationCmd(consumableId, startDate, endDate, storageId, quantity, reservationId, successMsg, errorMsg) {
    const url = `/api/consumables/${ConsumableId__get_unwrap(consumableId)}/reservations/check?startDate=${toISOString(startDate)}&endDate=${toISOString(endDate)}&storageId=${StorageId__get_unwrap(storageId)}&quantity=${quantity}`;
    return Cmd_OfPromise_either((url_2) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_4;
        return ((url_4 = addPrefix(url_2), (refreshOn) => fetchWithDecoder(url_4, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), bool_type));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), (reservationId == null) ? url : (`${url}&reservationId=${ConsumableReservationId__get_unwrap(reservationId)}`), successMsg, errorMsg);
}

export function updateStorageCmd(consumableId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/consumables/${unwrapConsumableId(consumableId)}/switch-storage`, toString(0, Auto_generateBoxedEncoder_437914C6(UpdateConsumableStorageDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function deleteConsumable(consumableId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), `/api/consumables/${ConsumableId__get_unwrap(consumableId)}`, successMsg, errorMsg);
}

export function putStockChangeCmd(consumableId, dto, successMsg, errorMsg) {
    const body = toString(0, Auto_generateBoxedEncoder_437914C6(UpdateConsumableStockDto_$reflection(), undefined, undefined, undefined)(dto));
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/consumables/${ConsumableId__get_unwrap(consumableId)}/stock`, body], successMsg, errorMsg);
}

