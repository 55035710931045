import { Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { anonRecord_type, bool_type, class_type, list_type, option_type, int32_type, record_type, float64_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class MultiAssignConsumableItemDto extends Record {
    constructor(Id, ConsumableId, Quantity, StorageId, CommissionNumber) {
        super();
        this.Id = Id;
        this.ConsumableId = ConsumableId;
        this.Quantity = Quantity;
        this.StorageId = StorageId;
        this.CommissionNumber = CommissionNumber;
    }
}

export function MultiAssignConsumableItemDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiAssignConsumableItemDto", [], MultiAssignConsumableItemDto, () => [["Id", string_type], ["ConsumableId", string_type], ["Quantity", float64_type], ["StorageId", string_type], ["CommissionNumber", string_type]]);
}

export class MultiAssignToolWithQuantityItemDto extends Record {
    constructor(Id, ToolId, Quantity, StorageId, SenderId) {
        super();
        this.Id = Id;
        this.ToolId = ToolId;
        this.Quantity = (Quantity | 0);
        this.StorageId = StorageId;
        this.SenderId = SenderId;
    }
}

export function MultiAssignToolWithQuantityItemDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiAssignToolWithQuantityItemDto", [], MultiAssignToolWithQuantityItemDto, () => [["Id", string_type], ["ToolId", string_type], ["Quantity", int32_type], ["StorageId", string_type], ["SenderId", option_type(string_type)]]);
}

export class MultiAssignToolItemDto extends Record {
    constructor(Id, ToolId) {
        super();
        this.Id = Id;
        this.ToolId = ToolId;
    }
}

export function MultiAssignToolItemDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiAssignToolItemDto", [], MultiAssignToolItemDto, () => [["Id", string_type], ["ToolId", string_type]]);
}

export class MultiAssignSetItemDto extends Record {
    constructor(Id, SetId) {
        super();
        this.Id = Id;
        this.SetId = SetId;
    }
}

export function MultiAssignSetItemDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiAssignSetItemDto", [], MultiAssignSetItemDto, () => [["Id", string_type], ["SetId", string_type]]);
}

export class MultiAssignDto extends Record {
    constructor(ReceiverId, Tools, Sets, Consumables, ToolsWithQuantity, Comment$, SignatureUrl, PlannedReturnDate) {
        super();
        this.ReceiverId = ReceiverId;
        this.Tools = Tools;
        this.Sets = Sets;
        this.Consumables = Consumables;
        this.ToolsWithQuantity = ToolsWithQuantity;
        this.Comment = Comment$;
        this.SignatureUrl = SignatureUrl;
        this.PlannedReturnDate = PlannedReturnDate;
    }
}

export function MultiAssignDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiAssignDto", [], MultiAssignDto, () => [["ReceiverId", string_type], ["Tools", list_type(MultiAssignToolItemDto_$reflection())], ["Sets", list_type(MultiAssignSetItemDto_$reflection())], ["Consumables", list_type(MultiAssignConsumableItemDto_$reflection())], ["ToolsWithQuantity", list_type(MultiAssignToolWithQuantityItemDto_$reflection())], ["Comment", string_type], ["SignatureUrl", option_type(string_type)], ["PlannedReturnDate", option_type(string_type)]]);
}

export class MultiUnassignConsumableDto extends Record {
    constructor(Id, ConsumableId, Quantity, StorageId, SenderId) {
        super();
        this.Id = Id;
        this.ConsumableId = ConsumableId;
        this.Quantity = Quantity;
        this.StorageId = StorageId;
        this.SenderId = SenderId;
    }
}

export function MultiUnassignConsumableDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiUnassignConsumableDto", [], MultiUnassignConsumableDto, () => [["Id", string_type], ["ConsumableId", string_type], ["Quantity", float64_type], ["StorageId", string_type], ["SenderId", string_type]]);
}

export class MultiUnassignToolWithQuantityDto extends Record {
    constructor(Id, ToolId, Quantity, StorageId, SenderId) {
        super();
        this.Id = Id;
        this.ToolId = ToolId;
        this.Quantity = (Quantity | 0);
        this.StorageId = StorageId;
        this.SenderId = SenderId;
    }
}

export function MultiUnassignToolWithQuantityDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiUnassignToolWithQuantityDto", [], MultiUnassignToolWithQuantityDto, () => [["Id", string_type], ["ToolId", string_type], ["Quantity", int32_type], ["StorageId", string_type], ["SenderId", string_type]]);
}

export class MultiUnassignSetDto extends Record {
    constructor(Id, SetId) {
        super();
        this.Id = Id;
        this.SetId = SetId;
    }
}

export function MultiUnassignSetDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiUnassignSetDto", [], MultiUnassignSetDto, () => [["Id", string_type], ["SetId", string_type]]);
}

export class MultiUnassignToolDto extends Record {
    constructor(Id, ToolId) {
        super();
        this.Id = Id;
        this.ToolId = ToolId;
    }
}

export function MultiUnassignToolDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiUnassignToolDto", [], MultiUnassignToolDto, () => [["Id", string_type], ["ToolId", string_type]]);
}

export class MultiUnassignDto extends Record {
    constructor(Tools, Sets, Consumables, ToolsWithQuantity, Comment$) {
        super();
        this.Tools = Tools;
        this.Sets = Sets;
        this.Consumables = Consumables;
        this.ToolsWithQuantity = ToolsWithQuantity;
        this.Comment = Comment$;
    }
}

export function MultiUnassignDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiUnassignDto", [], MultiUnassignDto, () => [["Tools", list_type(MultiUnassignToolDto_$reflection())], ["Sets", list_type(MultiUnassignSetDto_$reflection())], ["Consumables", list_type(MultiUnassignConsumableDto_$reflection())], ["ToolsWithQuantity", list_type(MultiUnassignToolWithQuantityDto_$reflection())], ["Comment", string_type]]);
}

export class MultiStateLostToolWithQuantityDto extends Record {
    constructor(ToolId, Quantity) {
        super();
        this.ToolId = ToolId;
        this.Quantity = (Quantity | 0);
    }
}

export function MultiStateLostToolWithQuantityDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiStateLostToolWithQuantityDto", [], MultiStateLostToolWithQuantityDto, () => [["ToolId", string_type], ["Quantity", int32_type]]);
}

export class MultiUpdateStateToLostStateDto extends Record {
    constructor(ToolIds, SetIds, ToolsWithQuantity, ReceiverId, Comment$, LostDate) {
        super();
        this.ToolIds = ToolIds;
        this.SetIds = SetIds;
        this.ToolsWithQuantity = ToolsWithQuantity;
        this.ReceiverId = ReceiverId;
        this.Comment = Comment$;
        this.LostDate = LostDate;
    }
}

export function MultiUpdateStateToLostStateDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiUpdateStateToLostStateDto", [], MultiUpdateStateToLostStateDto, () => [["ToolIds", list_type(string_type)], ["SetIds", list_type(string_type)], ["ToolsWithQuantity", list_type(MultiStateLostToolWithQuantityDto_$reflection())], ["ReceiverId", option_type(string_type)], ["Comment", string_type], ["LostDate", option_type(string_type)]]);
}

export class ConsumableReservation extends Record {
    constructor(ConsumableId, StorageId, Quantity) {
        super();
        this.ConsumableId = ConsumableId;
        this.StorageId = StorageId;
        this.Quantity = Quantity;
    }
}

export function ConsumableReservation_$reflection() {
    return record_type("Shared.MultiOperation.ConsumableReservation", [], ConsumableReservation, () => [["ConsumableId", string_type], ["StorageId", string_type], ["Quantity", float64_type]]);
}

export class ToolWithQuantityReservation extends Record {
    constructor(ToolId, StorageId, Quantity) {
        super();
        this.ToolId = ToolId;
        this.StorageId = StorageId;
        this.Quantity = (Quantity | 0);
    }
}

export function ToolWithQuantityReservation_$reflection() {
    return record_type("Shared.MultiOperation.ToolWithQuantityReservation", [], ToolWithQuantityReservation, () => [["ToolId", string_type], ["StorageId", string_type], ["Quantity", int32_type]]);
}

export class MultiReservationCreateDto extends Record {
    constructor(Description, Name, StartDate, EndDate, NotifyUser, ReceiverId, ConsumableReservations, ToolWithQuantityReservations, SetIds, ToolIds) {
        super();
        this.Description = Description;
        this.Name = Name;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.NotifyUser = NotifyUser;
        this.ReceiverId = ReceiverId;
        this.ConsumableReservations = ConsumableReservations;
        this.ToolWithQuantityReservations = ToolWithQuantityReservations;
        this.SetIds = SetIds;
        this.ToolIds = ToolIds;
    }
}

export function MultiReservationCreateDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiReservationCreateDto", [], MultiReservationCreateDto, () => [["Description", string_type], ["Name", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["NotifyUser", option_type(bool_type)], ["ReceiverId", string_type], ["ConsumableReservations", list_type(ConsumableReservation_$reflection())], ["ToolWithQuantityReservations", list_type(ToolWithQuantityReservation_$reflection())], ["SetIds", list_type(string_type)], ["ToolIds", list_type(string_type)]]);
}

export class MultiReservationUpdateDto extends Record {
    constructor(Description, Name, StartDate, ReservationId, ReservationGroupId, EndDate, NotifyUser, ReceiverId, ConsumableReservations, ToolWithQuantityReservations, SetIds, ToolIds) {
        super();
        this.Description = Description;
        this.Name = Name;
        this.StartDate = StartDate;
        this.ReservationId = ReservationId;
        this.ReservationGroupId = ReservationGroupId;
        this.EndDate = EndDate;
        this.NotifyUser = NotifyUser;
        this.ReceiverId = ReceiverId;
        this.ConsumableReservations = ConsumableReservations;
        this.ToolWithQuantityReservations = ToolWithQuantityReservations;
        this.SetIds = SetIds;
        this.ToolIds = ToolIds;
    }
}

export function MultiReservationUpdateDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiReservationUpdateDto", [], MultiReservationUpdateDto, () => [["Description", string_type], ["Name", string_type], ["StartDate", class_type("System.DateTime")], ["ReservationId", string_type], ["ReservationGroupId", option_type(string_type)], ["EndDate", class_type("System.DateTime")], ["NotifyUser", option_type(bool_type)], ["ReceiverId", string_type], ["ConsumableReservations", list_type(ConsumableReservation_$reflection())], ["ToolWithQuantityReservations", list_type(ToolWithQuantityReservation_$reflection())], ["SetIds", list_type(string_type)], ["ToolIds", list_type(string_type)]]);
}

export class MultiAssignNotifcationDto extends Record {
    constructor(ReceiverId, PlannedReturnDate, WithSignature, Comment$, ToolIds, SetIds, Consumables, ToolsWithQuantity) {
        super();
        this.ReceiverId = ReceiverId;
        this.PlannedReturnDate = PlannedReturnDate;
        this.WithSignature = WithSignature;
        this.Comment = Comment$;
        this.ToolIds = ToolIds;
        this.SetIds = SetIds;
        this.Consumables = Consumables;
        this.ToolsWithQuantity = ToolsWithQuantity;
    }
}

export function MultiAssignNotifcationDto_$reflection() {
    return record_type("Shared.MultiOperation.MultiAssignNotifcationDto", [], MultiAssignNotifcationDto, () => [["ReceiverId", string_type], ["PlannedReturnDate", option_type(class_type("System.DateTime"))], ["WithSignature", bool_type], ["Comment", string_type], ["ToolIds", list_type(anonRecord_type(["Id", string_type], ["ToolId", string_type]))], ["SetIds", list_type(anonRecord_type(["Id", string_type], ["SetId", string_type]))], ["Consumables", list_type(anonRecord_type(["ConsumableId", string_type], ["Id", string_type], ["Quantity", float64_type], ["StorageId", string_type]))], ["ToolsWithQuantity", list_type(anonRecord_type(["Id", string_type], ["Quantity", int32_type], ["StorageId", string_type], ["ToolId", string_type]))]]);
}

