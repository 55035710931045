import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { InformationAlert } from "../../Components/Alert.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { ReceiverCreateReservationAllowedConfiguration, ReceiverVisibilityConfiguration as ReceiverVisibilityConfiguration_1 } from "../../Shared/Shared.js";
import { Msg } from "./Types.js";
import { AppVariant, Variant } from "../../Variant.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { TextValue } from "../../Components/Text.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";

export function ReceiverActivationConfiguration(props) {
    let elems_1, elems;
    const t = useTranslation()[0];
    const config = props.CustomerConfiguration.ReceiverVisibilityConfiguration;
    return createElement("div", createObj(singleton_1((elems_1 = [createElement(InformationAlert, {
        Label: "settings.receivers_description",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-1"])], (elems = toList(delay(() => append(singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: config.Users,
        Label: t("receivers.users"),
        OnCheck: (value_1) => {
            props.Dispatch(new Msg(0, [new ReceiverVisibilityConfiguration_1(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, value_1, config.Vehicles)]));
        },
        TestId: "",
    })), delay(() => append(singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: config.Employees,
        Label: t("receivers.employees"),
        OnCheck: (value_2) => {
            props.Dispatch(new Msg(0, [new ReceiverVisibilityConfiguration_1(config.ConstructionSites, value_2, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
        },
        TestId: "",
    })), delay(() => append(singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: config.ConstructionSites,
        Label: t("receivers.constructionsites"),
        OnCheck: (value_3) => {
            props.Dispatch(new Msg(0, [new ReceiverVisibilityConfiguration_1(value_3, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
        },
        TestId: "",
    })), delay(() => append(singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: config.Projects,
        Label: t("receivers.projects"),
        OnCheck: (value_4) => {
            props.Dispatch(new Msg(0, [new ReceiverVisibilityConfiguration_1(config.ConstructionSites, config.Employees, config.ExternalPersons, value_4, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
        },
        TestId: "",
    })), delay(() => append(singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: config.Rooms,
        Label: t("receivers.rooms"),
        OnCheck: (value_5) => {
            props.Dispatch(new Msg(0, [new ReceiverVisibilityConfiguration_1(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, value_5, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
        },
        TestId: "",
    })), delay(() => append(singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: config.ExternalPersons,
        Label: t("receivers.externalpersons"),
        OnCheck: (value_6) => {
            props.Dispatch(new Msg(0, [new ReceiverVisibilityConfiguration_1(config.ConstructionSites, config.Employees, value_6, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
        },
        TestId: "",
    })), delay(() => append(singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: config.Subcontractos,
        Label: t("receivers.subcontractors"),
        OnCheck: (value_7) => {
            props.Dispatch(new Msg(0, [new ReceiverVisibilityConfiguration_1(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, value_7, config.Users, config.Vehicles)]));
        },
        TestId: "",
    })), delay(() => append(equals(Variant, new AppVariant(1, [])) ? append(singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: config.SchoolClasses,
        Label: t("receivers.school_classes"),
        OnCheck: (value_8) => {
            props.Dispatch(new Msg(0, [new ReceiverVisibilityConfiguration_1(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, value_8, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
        },
        TestId: "",
    })), delay(() => singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: config.Students,
        Label: t("receivers.students"),
        OnCheck: (value_9) => {
            props.Dispatch(new Msg(0, [new ReceiverVisibilityConfiguration_1(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, value_9, config.Subcontractos, config.Users, config.Vehicles)]));
        },
        TestId: "",
    })))) : empty(), delay(() => singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: config.Vehicles,
        Label: t("receivers.vehicles"),
        OnCheck: (value_10) => {
            props.Dispatch(new Msg(0, [new ReceiverVisibilityConfiguration_1(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, value_10)]));
        },
        TestId: "",
    })))))))))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))]))));
}

export function ReceiverVisibilityConfiguration(props) {
    let elems_1, elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_1 = [createElement(TextValue, {
        FontWeight: "bold",
        TestId: "",
        Text: t("settings.permissions_app_receiver_visibility"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-1"])], (elems = toList(delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Users ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.AppVisibilityPermissionReceiver.Users,
        Label: t("receivers.users"),
        OnCheck: (value_2) => {
            let bind$0040;
            props.Dispatch(new Msg(1, [(bind$0040 = props.CustomerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration_1(bind$0040.ConstructionSites, bind$0040.Employees, bind$0040.ExternalPersons, bind$0040.Projects, bind$0040.Rooms, bind$0040.SchoolClasses, bind$0040.Students, bind$0040.Subcontractos, value_2, bind$0040.Vehicles))]));
        },
        TestId: "receiver-visibility-user",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Employees ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.AppVisibilityPermissionReceiver.Employees,
        Label: t("receivers.employees"),
        OnCheck: (value_3) => {
            let bind$0040_1;
            props.Dispatch(new Msg(1, [(bind$0040_1 = props.CustomerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_1.ConstructionSites, value_3, bind$0040_1.ExternalPersons, bind$0040_1.Projects, bind$0040_1.Rooms, bind$0040_1.SchoolClasses, bind$0040_1.Students, bind$0040_1.Subcontractos, bind$0040_1.Users, bind$0040_1.Vehicles))]));
        },
        TestId: "receiver-visibility-employee",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.ConstructionSites ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.AppVisibilityPermissionReceiver.ConstructionSites,
        Label: t("receivers.constructionsites"),
        OnCheck: (value_4) => {
            let bind$0040_2;
            props.Dispatch(new Msg(1, [(bind$0040_2 = props.CustomerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration_1(value_4, bind$0040_2.Employees, bind$0040_2.ExternalPersons, bind$0040_2.Projects, bind$0040_2.Rooms, bind$0040_2.SchoolClasses, bind$0040_2.Students, bind$0040_2.Subcontractos, bind$0040_2.Users, bind$0040_2.Vehicles))]));
        },
        TestId: "receiver-visibility-construction-site",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Projects ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.AppVisibilityPermissionReceiver.Projects,
        Label: t("receivers.projects"),
        OnCheck: (value_5) => {
            let bind$0040_3;
            props.Dispatch(new Msg(1, [(bind$0040_3 = props.CustomerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_3.ConstructionSites, bind$0040_3.Employees, bind$0040_3.ExternalPersons, value_5, bind$0040_3.Rooms, bind$0040_3.SchoolClasses, bind$0040_3.Students, bind$0040_3.Subcontractos, bind$0040_3.Users, bind$0040_3.Vehicles))]));
        },
        TestId: "receiver-visibility-project",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Rooms ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.AppVisibilityPermissionReceiver.Rooms,
        Label: t("receivers.rooms"),
        OnCheck: (value_6) => {
            let bind$0040_4;
            props.Dispatch(new Msg(1, [(bind$0040_4 = props.CustomerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_4.ConstructionSites, bind$0040_4.Employees, bind$0040_4.ExternalPersons, bind$0040_4.Projects, value_6, bind$0040_4.SchoolClasses, bind$0040_4.Students, bind$0040_4.Subcontractos, bind$0040_4.Users, bind$0040_4.Vehicles))]));
        },
        TestId: "receiver-visibility-room",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.ExternalPersons ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.AppVisibilityPermissionReceiver.ExternalPersons,
        Label: t("receivers.externalpersons"),
        OnCheck: (value_7) => {
            let bind$0040_5;
            props.Dispatch(new Msg(1, [(bind$0040_5 = props.CustomerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_5.ConstructionSites, bind$0040_5.Employees, value_7, bind$0040_5.Projects, bind$0040_5.Rooms, bind$0040_5.SchoolClasses, bind$0040_5.Students, bind$0040_5.Subcontractos, bind$0040_5.Users, bind$0040_5.Vehicles))]));
        },
        TestId: "receiver-visibility-external-person",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Subcontractos ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.AppVisibilityPermissionReceiver.Subcontractos,
        Label: t("receivers.subcontractors"),
        OnCheck: (value_8) => {
            let bind$0040_6;
            props.Dispatch(new Msg(1, [(bind$0040_6 = props.CustomerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_6.ConstructionSites, bind$0040_6.Employees, bind$0040_6.ExternalPersons, bind$0040_6.Projects, bind$0040_6.Rooms, bind$0040_6.SchoolClasses, bind$0040_6.Students, value_8, bind$0040_6.Users, bind$0040_6.Vehicles))]));
        },
        TestId: "receiver-visibility-subcontractor",
    })) : empty(), delay(() => (equals(Variant, new AppVariant(1, [])) ? append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.SchoolClasses ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.AppVisibilityPermissionReceiver.SchoolClasses,
        Label: t("receivers.school_classes"),
        OnCheck: (value_9) => {
            let bind$0040_7;
            props.Dispatch(new Msg(1, [(bind$0040_7 = props.CustomerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_7.ConstructionSites, bind$0040_7.Employees, bind$0040_7.ExternalPersons, bind$0040_7.Projects, bind$0040_7.Rooms, value_9, bind$0040_7.Students, bind$0040_7.Subcontractos, bind$0040_7.Users, bind$0040_7.Vehicles))]));
        },
        TestId: "receiver-visibility-school-class",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Students ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.AppVisibilityPermissionReceiver.Students,
        Label: t("receivers.students"),
        OnCheck: (value_10) => {
            let bind$0040_8;
            props.Dispatch(new Msg(1, [(bind$0040_8 = props.CustomerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_8.ConstructionSites, bind$0040_8.Employees, bind$0040_8.ExternalPersons, bind$0040_8.Projects, bind$0040_8.Rooms, bind$0040_8.SchoolClasses, value_10, bind$0040_8.Subcontractos, bind$0040_8.Users, bind$0040_8.Vehicles))]));
        },
        TestId: "receiver-visibility-student",
    })) : empty(), delay(() => (props.CustomerConfiguration.ReceiverVisibilityConfiguration.Vehicles ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.AppVisibilityPermissionReceiver.Vehicles,
        Label: t("receivers.vehicles"),
        OnCheck: (value_11) => {
            let bind$0040_9;
            props.Dispatch(new Msg(1, [(bind$0040_9 = props.CustomerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_9.ConstructionSites, bind$0040_9.Employees, bind$0040_9.ExternalPersons, bind$0040_9.Projects, bind$0040_9.Rooms, bind$0040_9.SchoolClasses, bind$0040_9.Students, bind$0040_9.Subcontractos, bind$0040_9.Users, value_11))]));
        },
        TestId: "receiver-visibility-vehicle",
    })) : empty()))))) : empty()))))))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(InformationAlert, {
        Label: t("settings.permissions_app_receiver_visibility_info"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function ReceiverCreateReservationConfiguration(props) {
    let elems_1, elems;
    const t = useTranslation()[0];
    const componentState = props.CustomerConfiguration.IsSelfServiceReservationForUserActive ? "enabled" : "disabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_1 = [createElement(TextValue, {
        FontWeight: "bold",
        TestId: "",
        Text: t("settings.permissions_receiver_create_reservation_permission"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-1"])], (elems = toList(delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Users ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration.Users,
        Label: t("receivers.users"),
        OnCheck: (value_2) => {
            let bind$0040;
            props.Dispatch(new Msg(2, [(bind$0040 = props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration(bind$0040.ConstructionSites, bind$0040.Employees, bind$0040.ExternalPersons, bind$0040.Projects, bind$0040.Rooms, bind$0040.SchoolClasses, bind$0040.Students, bind$0040.Subcontractos, value_2, bind$0040.Vehicles))]));
        },
        TestId: "create-reservation-checkbox-users",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Employees ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration.Employees,
        Label: t("receivers.employees"),
        OnCheck: (value_3) => {
            let bind$0040_1;
            props.Dispatch(new Msg(2, [(bind$0040_1 = props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration(bind$0040_1.ConstructionSites, value_3, bind$0040_1.ExternalPersons, bind$0040_1.Projects, bind$0040_1.Rooms, bind$0040_1.SchoolClasses, bind$0040_1.Students, bind$0040_1.Subcontractos, bind$0040_1.Users, bind$0040_1.Vehicles))]));
        },
        TestId: "create-reservation-checkbox-employees",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.ConstructionSites ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration.ConstructionSites,
        Label: t("receivers.constructionsites"),
        OnCheck: (value_4) => {
            let bind$0040_2;
            props.Dispatch(new Msg(2, [(bind$0040_2 = props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration(value_4, bind$0040_2.Employees, bind$0040_2.ExternalPersons, bind$0040_2.Projects, bind$0040_2.Rooms, bind$0040_2.SchoolClasses, bind$0040_2.Students, bind$0040_2.Subcontractos, bind$0040_2.Users, bind$0040_2.Vehicles))]));
        },
        TestId: "create-reservation-checkbox-constructionsites",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Projects ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration.Projects,
        Label: t("receivers.projects"),
        OnCheck: (value_5) => {
            let bind$0040_3;
            props.Dispatch(new Msg(2, [(bind$0040_3 = props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration(bind$0040_3.ConstructionSites, bind$0040_3.Employees, bind$0040_3.ExternalPersons, value_5, bind$0040_3.Rooms, bind$0040_3.SchoolClasses, bind$0040_3.Students, bind$0040_3.Subcontractos, bind$0040_3.Users, bind$0040_3.Vehicles))]));
        },
        TestId: "create-reservation-checkbox-projects",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Rooms ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration.Rooms,
        Label: t("receivers.rooms"),
        OnCheck: (value_6) => {
            let bind$0040_4;
            props.Dispatch(new Msg(2, [(bind$0040_4 = props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration(bind$0040_4.ConstructionSites, bind$0040_4.Employees, bind$0040_4.ExternalPersons, bind$0040_4.Projects, value_6, bind$0040_4.SchoolClasses, bind$0040_4.Students, bind$0040_4.Subcontractos, bind$0040_4.Users, bind$0040_4.Vehicles))]));
        },
        TestId: "create-reservation-checkbox-rooms",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.ExternalPersons ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration.ExternalPersons,
        Label: t("receivers.externalpersons"),
        OnCheck: (value_7) => {
            let bind$0040_5;
            props.Dispatch(new Msg(2, [(bind$0040_5 = props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration(bind$0040_5.ConstructionSites, bind$0040_5.Employees, value_7, bind$0040_5.Projects, bind$0040_5.Rooms, bind$0040_5.SchoolClasses, bind$0040_5.Students, bind$0040_5.Subcontractos, bind$0040_5.Users, bind$0040_5.Vehicles))]));
        },
        TestId: "create-reservation-checkbox-externalpersons",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Subcontractos ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration.Subcontractos,
        Label: t("receivers.subcontractors"),
        OnCheck: (value_8) => {
            let bind$0040_6;
            props.Dispatch(new Msg(2, [(bind$0040_6 = props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration(bind$0040_6.ConstructionSites, bind$0040_6.Employees, bind$0040_6.ExternalPersons, bind$0040_6.Projects, bind$0040_6.Rooms, bind$0040_6.SchoolClasses, bind$0040_6.Students, value_8, bind$0040_6.Users, bind$0040_6.Vehicles))]));
        },
        TestId: "create-reservation-checkbox-subcontractors",
    })) : empty(), delay(() => (equals(Variant, new AppVariant(1, [])) ? append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.SchoolClasses ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration.SchoolClasses,
        Label: t("receivers.school_classes"),
        OnCheck: (value_9) => {
            let bind$0040_7;
            props.Dispatch(new Msg(2, [(bind$0040_7 = props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration(bind$0040_7.ConstructionSites, bind$0040_7.Employees, bind$0040_7.ExternalPersons, bind$0040_7.Projects, bind$0040_7.Rooms, value_9, bind$0040_7.Students, bind$0040_7.Subcontractos, bind$0040_7.Users, bind$0040_7.Vehicles))]));
        },
        TestId: "create-reservation-checkbox-school_classes",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Students ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration.Students,
        Label: t("receivers.students"),
        OnCheck: (value_10) => {
            let bind$0040_8;
            props.Dispatch(new Msg(2, [(bind$0040_8 = props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration(bind$0040_8.ConstructionSites, bind$0040_8.Employees, bind$0040_8.ExternalPersons, bind$0040_8.Projects, bind$0040_8.Rooms, bind$0040_8.SchoolClasses, value_10, bind$0040_8.Subcontractos, bind$0040_8.Users, bind$0040_8.Vehicles))]));
        },
        TestId: "create-reservation-checkbox-students",
    })) : empty(), delay(() => (props.CustomerConfiguration.ReceiverVisibilityConfiguration.Vehicles ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration.Vehicles,
        Label: t("receivers.vehicles"),
        OnCheck: (value_11) => {
            let bind$0040_9;
            props.Dispatch(new Msg(2, [(bind$0040_9 = props.CustomerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration(bind$0040_9.ConstructionSites, bind$0040_9.Employees, bind$0040_9.ExternalPersons, bind$0040_9.Projects, bind$0040_9.Rooms, bind$0040_9.SchoolClasses, bind$0040_9.Students, bind$0040_9.Subcontractos, bind$0040_9.Users, value_11))]));
        },
        TestId: "create-reservation-checkbox-vehicles",
    })) : empty()))))) : empty()))))))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function CanToolManagerCreateReceiverConfiguration(props) {
    let elems_1, elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_1 = [createElement(TextValue, {
        FontWeight: "bold",
        TestId: "",
        Text: t("settings.permissions_can_tool_manager_create_receiver"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-1"])], (elems = toList(delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Employees ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.ToolManagerCanCreateReceiver.Employees,
        Label: t("receivers.employees"),
        OnCheck: (value_2) => {
            let bind$0040;
            props.Dispatch(new Msg(3, [(bind$0040 = props.CustomerConfiguration.ToolManagerCanCreateReceiver, new ReceiverVisibilityConfiguration_1(bind$0040.ConstructionSites, value_2, bind$0040.ExternalPersons, bind$0040.Projects, bind$0040.Rooms, bind$0040.SchoolClasses, bind$0040.Students, bind$0040.Subcontractos, bind$0040.Users, bind$0040.Vehicles))]));
        },
        TestId: "can-tool-manager-create-employee",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.ConstructionSites ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.ToolManagerCanCreateReceiver.ConstructionSites,
        Label: t("receivers.constructionsites"),
        OnCheck: (value_3) => {
            let bind$0040_1;
            props.Dispatch(new Msg(3, [(bind$0040_1 = props.CustomerConfiguration.ToolManagerCanCreateReceiver, new ReceiverVisibilityConfiguration_1(value_3, bind$0040_1.Employees, bind$0040_1.ExternalPersons, bind$0040_1.Projects, bind$0040_1.Rooms, bind$0040_1.SchoolClasses, bind$0040_1.Students, bind$0040_1.Subcontractos, bind$0040_1.Users, bind$0040_1.Vehicles))]));
        },
        TestId: "can-tool-manager-create-construction-site",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Projects ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.ToolManagerCanCreateReceiver.Projects,
        Label: t("receivers.projects"),
        OnCheck: (value_4) => {
            let bind$0040_2;
            props.Dispatch(new Msg(3, [(bind$0040_2 = props.CustomerConfiguration.ToolManagerCanCreateReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_2.ConstructionSites, bind$0040_2.Employees, bind$0040_2.ExternalPersons, value_4, bind$0040_2.Rooms, bind$0040_2.SchoolClasses, bind$0040_2.Students, bind$0040_2.Subcontractos, bind$0040_2.Users, bind$0040_2.Vehicles))]));
        },
        TestId: "can-tool-manager-create-project",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Rooms ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.ToolManagerCanCreateReceiver.Rooms,
        Label: t("receivers.rooms"),
        OnCheck: (value_5) => {
            let bind$0040_3;
            props.Dispatch(new Msg(3, [(bind$0040_3 = props.CustomerConfiguration.ToolManagerCanCreateReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_3.ConstructionSites, bind$0040_3.Employees, bind$0040_3.ExternalPersons, bind$0040_3.Projects, value_5, bind$0040_3.SchoolClasses, bind$0040_3.Students, bind$0040_3.Subcontractos, bind$0040_3.Users, bind$0040_3.Vehicles))]));
        },
        TestId: "can-tool-manager-create-room",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.ExternalPersons ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.ToolManagerCanCreateReceiver.ExternalPersons,
        Label: t("receivers.externalpersons"),
        OnCheck: (value_6) => {
            let bind$0040_4;
            props.Dispatch(new Msg(3, [(bind$0040_4 = props.CustomerConfiguration.ToolManagerCanCreateReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_4.ConstructionSites, bind$0040_4.Employees, value_6, bind$0040_4.Projects, bind$0040_4.Rooms, bind$0040_4.SchoolClasses, bind$0040_4.Students, bind$0040_4.Subcontractos, bind$0040_4.Users, bind$0040_4.Vehicles))]));
        },
        TestId: "can-tool-manager-create-external-person",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Subcontractos ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.ToolManagerCanCreateReceiver.Subcontractos,
        Label: t("receivers.subcontractors"),
        OnCheck: (value_7) => {
            let bind$0040_5;
            props.Dispatch(new Msg(3, [(bind$0040_5 = props.CustomerConfiguration.ToolManagerCanCreateReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_5.ConstructionSites, bind$0040_5.Employees, bind$0040_5.ExternalPersons, bind$0040_5.Projects, bind$0040_5.Rooms, bind$0040_5.SchoolClasses, bind$0040_5.Students, value_7, bind$0040_5.Users, bind$0040_5.Vehicles))]));
        },
        TestId: "can-tool-manager-create-subcontractor",
    })) : empty(), delay(() => (equals(Variant, new AppVariant(1, [])) ? append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.SchoolClasses ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.ToolManagerCanCreateReceiver.SchoolClasses,
        Label: t("receivers.school_classes"),
        OnCheck: (value_8) => {
            let bind$0040_6;
            props.Dispatch(new Msg(3, [(bind$0040_6 = props.CustomerConfiguration.ToolManagerCanCreateReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_6.ConstructionSites, bind$0040_6.Employees, bind$0040_6.ExternalPersons, bind$0040_6.Projects, bind$0040_6.Rooms, value_8, bind$0040_6.Students, bind$0040_6.Subcontractos, bind$0040_6.Users, bind$0040_6.Vehicles))]));
        },
        TestId: "can-tool-manager-create-school-class",
    })) : empty(), delay(() => append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Students ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.ToolManagerCanCreateReceiver.Students,
        Label: t("receivers.students"),
        OnCheck: (value_9) => {
            let bind$0040_7;
            props.Dispatch(new Msg(3, [(bind$0040_7 = props.CustomerConfiguration.ToolManagerCanCreateReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_7.ConstructionSites, bind$0040_7.Employees, bind$0040_7.ExternalPersons, bind$0040_7.Projects, bind$0040_7.Rooms, bind$0040_7.SchoolClasses, value_9, bind$0040_7.Subcontractos, bind$0040_7.Users, bind$0040_7.Vehicles))]));
        },
        TestId: "can-tool-manager-create-student",
    })) : empty(), delay(() => (props.CustomerConfiguration.ReceiverVisibilityConfiguration.Vehicles ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: props.CustomerConfiguration.ToolManagerCanCreateReceiver.Vehicles,
        Label: t("receivers.vehicles"),
        OnCheck: (value_10) => {
            let bind$0040_8;
            props.Dispatch(new Msg(3, [(bind$0040_8 = props.CustomerConfiguration.ToolManagerCanCreateReceiver, new ReceiverVisibilityConfiguration_1(bind$0040_8.ConstructionSites, bind$0040_8.Employees, bind$0040_8.ExternalPersons, bind$0040_8.Projects, bind$0040_8.Rooms, bind$0040_8.SchoolClasses, bind$0040_8.Students, bind$0040_8.Subcontractos, bind$0040_8.Users, value_10))]));
        },
        TestId: "can-tool-manager-create-vehicle",
    })) : empty()))))) : empty()))))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function ReceiverSettings() {
    let elems;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init_1, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = patternInput[0].CustomerConfiguration;
    if (matchValue.tag === 0) {
        return defaultOf();
    }
    else {
        const customerConfiguration = matchValue.fields[0];
        return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-5"])], (elems = [createElement(ReceiverActivationConfiguration, {
            CustomerConfiguration: customerConfiguration,
            Dispatch: dispatch,
        }), createElement(ReceiverVisibilityConfiguration, {
            CustomerConfiguration: customerConfiguration,
            Dispatch: dispatch,
        }), createElement(ReceiverCreateReservationConfiguration, {
            CustomerConfiguration: customerConfiguration,
            Dispatch: dispatch,
        }), createElement(CanToolManagerCreateReceiverConfiguration, {
            CustomerConfiguration: customerConfiguration,
            Dispatch: dispatch,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
    }
}

