import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { CalendarEntryDto_$reflection } from "../Shared/Calendar.js";
import { union_type, class_type, record_type, list_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { TokenDataDto_$reflection, RequestedValue$1_$reflection } from "../Shared/Shared.js";

export class State extends Record {
    constructor(CalendarData, UserData) {
        super();
        this.CalendarData = CalendarData;
        this.UserData = UserData;
    }
}

export function State_$reflection() {
    return record_type("Calendar.Types.State", [], State, () => [["CalendarData", RequestedValue$1_$reflection(list_type(CalendarEntryDto_$reflection()))], ["UserData", TokenDataDto_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CalendarDataFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Calendar.Types.Msg", [], Msg, () => [[["Item", list_type(CalendarEntryDto_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

