import { Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { ofNullable } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { keyValueList } from "../../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { singleton, empty, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { fetchWithDecoder, addPrefix, token as token_2 } from "../../Communication.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetch$ } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { printf, toText } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { DocumentDetail_$reflection, ConsumableId, RequestedValue$1, EmptyResponse_$reflection, PostResponse$1_$reflection, ImageDeletedResponse_$reflection } from "../../Shared/Shared.js";
import { State, Msg } from "./Types.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise as promise_1 } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { downloadFileCmd as downloadFileCmd_1, deleteQrCodes, getConsumableHistoryDto, getConsumableDetailDto } from "../../Requests/Consumable.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { UserConfiguration as UserConfiguration_1, QuickOverviewConfiguration as QuickOverviewConfiguration_1, UserConfiguration_$reflection } from "../../Shared/User.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { unwrapConsumableId } from "../../Shared/Helper.js";
import { getConsumableNotifications } from "../../Requests/Notification.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { unwrapStorageId } from "../../Shared/Helper.js";
import { UnassignQRCode, AssignQRCode_$reflection, AssignQRCodeResult_$reflection, AssignQRCode } from "../../Shared/Tool.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { getChangelogs } from "../../Requests/Changelog.js";

export function downloadFileCmd(consumableId, documentId, fileName, success, error) {
    return Cmd_OfPromise_either((tupledArg) => {
        const body_1 = tupledArg[2];
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const tenant = ofNullable(localStorage.getItem("tenant"));
            const props = (body_1 == null) ? ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue_2;
                return append((matchValue_2 = token_2(undefined), (matchValue_2 == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_3 = tenant;
                    if (matchValue_3 == null) {
                        return empty();
                    }
                    else {
                        return singleton(["X-ONE-Tenant", matchValue_3]);
                    }
                }))));
            })), 0)])]) : ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [body_1]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue;
                return append((matchValue = token_2(undefined), (matchValue == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_1 = tenant;
                    if (matchValue_1 == null) {
                        return empty();
                    }
                    else {
                        return singleton(["X-ONE-Tenant", matchValue_1]);
                    }
                }))));
            })), 0)])]);
            return fetch$(addPrefix(tupledArg[0]), props).then((_arg) => (Promise.resolve([_arg, tupledArg[1]])));
        }));
    }, [toText(printf("/api/consumables/%s/files/%s"))(consumableId.fields[0])(documentId.fields[0]), fileName, undefined], success, error);
}

export function deleteImageCmd(consumableId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton_1(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ImageDeletedResponse_$reflection())));
    })))), `/api/consumables/${consumableId.fields[0]}/image`, (Item) => (new Msg(9, [Item])), (Item_1) => (new Msg(29, [Item_1])));
}

export function saveImageCmd(consumableId, formData) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), undefined, (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), [`/api/consumables/${consumableId.fields[0]}/image`, formData], (Item) => (new Msg(10, [Item])), (Item_1) => (new Msg(29, [Item_1])));
}

export function extractBlob(response, filename) {
    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response.blob().then((_arg) => (Promise.resolve([_arg, filename]))))));
}

export function getBlob(response, filename) {
    return Cmd_OfPromise_either((tupledArg) => extractBlob(tupledArg[0], tupledArg[1]), [response, filename], (tupledArg_1) => (new Msg(17, [tupledArg_1[0], tupledArg_1[1]])), (Item_2) => (new Msg(29, [Item_2])));
}

export function initViewConsumable(consumableId, userData) {
    return [new State(new RequestedValue$1(0, []), userData, new RequestedValue$1(0, []), new RequestedValue$1(0, []), false, userData.Customer, {
        IsLoading: false,
        IsOpen: false,
    }, new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, [])), Cmd_batch(ofArray([getConsumableDetailDto((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(29, [Item_1])), new ConsumableId(consumableId)), getConsumableHistoryDto((Item_2) => (new Msg(6, [Item_2])), (Item_3) => (new Msg(29, [Item_3])), new ConsumableId(consumableId)), Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton_1(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), UserConfiguration_$reflection()));
    })))), "/api/users/configuration", (Item_4) => (new Msg(23, [Item_4])), (Item_5) => (new Msg(29, [Item_5]))), Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton_1(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_6) => (new Msg(24, [Item_6])), (Item_7) => (new Msg(29, [Item_7]))), Cmd_OfPromise_either_1((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton_1(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(DocumentDetail_$reflection())));
    })))), `/api/consumables/${unwrapConsumableId(new ConsumableId(consumableId))}/documents`, (Item_8) => (new Msg(18, [Item_8])), (Item_9) => (new Msg(29, [Item_9]))), getConsumableNotifications(new ConsumableId(consumableId), (Item_10) => (new Msg(26, [Item_10])), (Item_11) => (new Msg(29, [Item_11])))]))];
}

export function update(msg, state) {
    let matchValue_3, userConfiguration_1, bind$0040_1;
    switch (msg.tag) {
        case 1:
            return [new State(state.Consumables, state.UserData, state.Storages, state.History, state.ImageViewerOpen, state.Customer, {
                IsLoading: state.QuickOverviewDialog.IsLoading,
                IsOpen: !state.QuickOverviewDialog.IsOpen,
            }, state.UserConfiguration, state.Documents, state.Notifications, state.Changelogs), Cmd_none()];
        case 2:
            return [new State(state.Consumables, state.UserData, state.Storages, state.History, true, state.Customer, state.QuickOverviewDialog, state.UserConfiguration, state.Documents, state.Notifications, state.Changelogs), Cmd_none()];
        case 3:
            return [new State(state.Consumables, state.UserData, state.Storages, state.History, false, state.Customer, state.QuickOverviewDialog, state.UserConfiguration, state.Documents, state.Notifications, state.Changelogs), Cmd_none()];
        case 4: {
            const matchValue_1 = state.Consumables;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const consumable = matchValue_1.fields[0];
                return [state, Cmd_batch(ofArray([getConsumableDetailDto((Item_2) => (new Msg(5, [Item_2])), (Item_3) => (new Msg(29, [Item_3])), new ConsumableId(parse(consumable.Id))), getConsumableHistoryDto((Item_5) => (new Msg(6, [Item_5])), (Item_6) => (new Msg(29, [Item_6])), new ConsumableId(parse(consumable.Id)))]))];
            }
        }
        case 11: {
            const matchValue_2 = state.Consumables;
            if (matchValue_2.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const dto = new AssignQRCode(matchValue_2.fields[0].Id, msg.fields[0], unwrapStorageId(msg.fields[1]));
                return [state, Cmd_OfPromise_either_1((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg_1[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                    const pr_1 = response_1.arrayBuffer();
                    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), PostResponse$1_$reflection(AssignQRCodeResult_$reflection())));
                })))), ["/api/consumables/assignqrcode", toString(0, Auto_generateBoxedEncoder_437914C6(AssignQRCode_$reflection(), undefined, undefined, undefined)(dto))], (Item_8) => (new Msg(12, [Item_8])), (Item_9) => (new Msg(29, [Item_9])))];
            }
        }
        case 12:
            return [state, (matchValue_3 = msg.fields[0].Result, (matchValue_3.tag === 1) ? Cmd_errorToast("assign_qr_code_dialog.qr_code_not_valid") : ((matchValue_3.tag === 2) ? Cmd_batch(ofArray([Cmd_successToast("tool.dialog_assign_qr_code"), singleton_1((dispatch) => {
                dispatch(new Msg(4, []));
            })])) : Cmd_errorToast("assign_qr_code_dialog.qr_code_assigned_to_another_tool")))];
        case 13: {
            const matchValue_4 = state.Consumables;
            if (matchValue_4.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, deleteQrCodes((Item_10) => (new Msg(14, [Item_10])), (Item_11) => (new Msg(29, [Item_11])), new UnassignQRCode(matchValue_4.fields[0].Id, singleton_1([msg.fields[0], unwrapStorageId(msg.fields[1])])))];
            }
        }
        case 14: {
            const matchValue_5 = state.Consumables;
            if (matchValue_5.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_batch(toList(delay(() => append(singleton(getConsumableDetailDto((Item_12) => (new Msg(5, [Item_12])), (Item_13) => (new Msg(29, [Item_13])), new ConsumableId(parse(matchValue_5.fields[0].Id)))), delay(() => {
                    if (msg.fields[0].Result.tag === 1) {
                        throw new Error("todo");
                        return empty();
                    }
                    else {
                        return singleton(Cmd_successToast("tool.dialog_tool_qr_delete_success"));
                    }
                })))))];
            }
        }
        case 5:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.UserData, state.Storages, state.History, state.ImageViewerOpen, state.Customer, state.QuickOverviewDialog, state.UserConfiguration, state.Documents, state.Notifications, state.Changelogs), singleton_1((dispatch_1) => {
                dispatch_1(new Msg(25, []));
            })];
        case 15:
            return [state, downloadFileCmd(msg.fields[0], msg.fields[1], msg.fields[2], (tupledArg_2) => (new Msg(16, [tupledArg_2[0], tupledArg_2[1]])), (Item_16) => (new Msg(29, [Item_16])))];
        case 16:
            return [state, getBlob(msg.fields[0], msg.fields[1])];
        case 17: {
            const url_6 = window.URL.createObjectURL(msg.fields[0]);
            const element = document.createElement("a");
            element.setAttribute("href", url_6);
            element.setAttribute("download", msg.fields[1]);
            document.body.appendChild(element);
            element.click();
            window.URL.revokeObjectURL(url_6);
            element.remove();
            return [state, Cmd_none()];
        }
        case 8: {
            const matchValue_7 = state.Consumables;
            if (matchValue_7.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, deleteImageCmd(new ConsumableId(parse(matchValue_7.fields[0].Id)))];
            }
        }
        case 9:
            return [state, Cmd_batch(ofArray([(msg.fields[0].Result.tag === 1) ? Cmd_errorToast("general.a_error_occured") : Cmd_successToast("delete_photo_dialog.delete_image_success"), singleton_1((dispatch_2) => {
                dispatch_2(new Msg(4, []));
            })]))];
        case 7: {
            const file = msg.fields[0];
            const matchValue_9 = state.Consumables;
            if (matchValue_9.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const formData = new FormData();
                formData.append(file.name, file);
                return [state, saveImageCmd(new ConsumableId(parse(matchValue_9.fields[0].Id)), formData)];
            }
        }
        case 10:
            return [state, Cmd_batch(ofArray([Cmd_successToast("general.upload_image_success"), singleton_1((dispatch_3) => {
                dispatch_3(new Msg(4, []));
            })]))];
        case 22:
            return [new State(state.Consumables, state.UserData, state.Storages, state.History, state.ImageViewerOpen, state.Customer, {
                IsLoading: false,
                IsOpen: false,
            }, state.UserConfiguration, state.Documents, state.Notifications, state.Changelogs), Cmd_batch(ofArray([Cmd_OfPromise_either_1((url_7) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_7), singleton_1(new Types_RequestProperties(0, ["GET"])), "application/json", (response_7) => {
                const pr_2 = response_7.arrayBuffer();
                return pr_2.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), UserConfiguration_$reflection()));
            })))), "/api/users/configuration", (Item_19) => (new Msg(23, [Item_19])), (Item_20) => (new Msg(29, [Item_20]))), Cmd_successToast("general.saved_successfully")]))];
        case 23:
            return [new State(state.Consumables, state.UserData, state.Storages, state.History, state.ImageViewerOpen, state.Customer, state.QuickOverviewDialog, new RequestedValue$1(1, [msg.fields[0]]), state.Documents, state.Notifications, state.Changelogs), Cmd_none()];
        case 19: {
            const matchValue_10 = state.Consumables;
            if (matchValue_10.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_OfPromise_either_1((url_10) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_10), singleton_1(new Types_RequestProperties(0, ["GET"])), "application/json", (response_8) => {
                    const pr_3 = response_8.arrayBuffer();
                    return pr_3.then((blob_4) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_4)), list_type(DocumentDetail_$reflection())));
                })))), `/api/consumables/${unwrapConsumableId(new ConsumableId(parse(matchValue_10.fields[0].Id)))}/documents`, (Item_22) => (new Msg(18, [Item_22])), (Item_23) => (new Msg(29, [Item_23])))];
            }
        }
        case 18:
            return [new State(state.Consumables, state.UserData, state.Storages, state.History, state.ImageViewerOpen, state.Customer, state.QuickOverviewDialog, state.UserConfiguration, new RequestedValue$1(1, [msg.fields[0]]), state.Notifications, state.Changelogs), Cmd_none()];
        case 6:
            return [new State(state.Consumables, state.UserData, state.Storages, new RequestedValue$1(1, [msg.fields[0]]), state.ImageViewerOpen, state.Customer, state.QuickOverviewDialog, state.UserConfiguration, state.Documents, state.Notifications, state.Changelogs), Cmd_none()];
        case 20: {
            const matchValue_11 = state.Consumables;
            if (matchValue_11.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_batch(ofArray([Cmd_successToast("general.download_started"), downloadFileCmd_1(new ConsumableId(parse(matchValue_11.fields[0].Id)), msg.fields[0], msg.fields[1], (tupledArg_3) => (new Msg(21, [tupledArg_3[0], tupledArg_3[1]])), (Item_27) => (new Msg(29, [Item_27])))]))];
            }
        }
        case 21:
            return [state, getBlob(msg.fields[0], msg.fields[1])];
        case 24:
            return [new State(state.Consumables, state.UserData, new RequestedValue$1(1, [msg.fields[0]]), state.History, state.ImageViewerOpen, state.Customer, state.QuickOverviewDialog, state.UserConfiguration, state.Documents, state.Notifications, state.Changelogs), Cmd_none()];
        case 25: {
            const matchValue_12 = state.Consumables;
            if (matchValue_12.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, getConsumableNotifications(new ConsumableId(parse(matchValue_12.fields[0].Id)), (Item_29) => (new Msg(26, [Item_29])), (Item_30) => (new Msg(29, [Item_30])))];
            }
        }
        case 26:
            return [new State(state.Consumables, state.UserData, state.Storages, state.History, state.ImageViewerOpen, state.Customer, state.QuickOverviewDialog, state.UserConfiguration, state.Documents, new RequestedValue$1(1, [msg.fields[0]]), state.Changelogs), Cmd_none()];
        case 27: {
            const matchValue_13 = state.Consumables;
            if (matchValue_13.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, getChangelogs(matchValue_13.fields[0].Id, (Item_31) => (new Msg(28, [Item_31])), (Item_32) => (new Msg(29, [Item_32])))];
            }
        }
        case 28:
            return [new State(state.Consumables, state.UserData, state.Storages, state.History, state.ImageViewerOpen, state.Customer, state.QuickOverviewDialog, state.UserConfiguration, state.Documents, state.Notifications, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 29:
            return [state, Cmd_errorToast("general.a_error_occured")];
        default: {
            const matchValue = state.UserConfiguration;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const userConfiguration = matchValue.fields[0];
                return [new State(state.Consumables, state.UserData, state.Storages, state.History, state.ImageViewerOpen, state.Customer, {
                    IsLoading: true,
                    IsOpen: state.QuickOverviewDialog.IsOpen,
                }, state.UserConfiguration, state.Documents, state.Notifications, state.Changelogs), (userConfiguration_1 = (new UserConfiguration_1(userConfiguration.TableConfiguration, (bind$0040_1 = userConfiguration.QuickOverviewConfiguration, new QuickOverviewConfiguration_1(msg.fields[0], bind$0040_1.ToolWithQuantityViewVisibleFieldKeys, bind$0040_1.ToolViewVisibleFieldKeys)))), Cmd_OfPromise_either_1((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                    const pr = response.arrayBuffer();
                    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), EmptyResponse_$reflection()));
                })))), ["/api/users/configuration", toString(0, Auto_generateBoxedEncoder_437914C6(UserConfiguration_$reflection(), undefined, undefined, undefined)(userConfiguration_1))], (Item) => (new Msg(22, [Item])), (Item_1) => (new Msg(29, [Item_1]))))];
            }
        }
    }
}

