import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { AreaAnchorMenu } from "../../Widgets/AnchorMenu.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { SectionContainer } from "../../Widgets/Container.js";
import { PermissionsView } from "../Permissions/View.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { ReceiverSettings } from "../Receivers/View.js";
import { SSOSettings } from "./SSO/View.js";

export function SystemSettingsView(props) {
    let elems_5, elems, elems_4;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState("permissions-section");
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[10rem,min(calc(100vw-16rem-2px-28px-11rem),80rem)]"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(AreaAnchorMenu, {
        Anchors: toList(delay(() => append(singleton({
            Id: "permissions-section",
            Label: t("settings.permissions"),
        }), delay(() => append(singleton({
            Id: "receivers-section",
            Label: t("settings.receivers"),
        }), delay(() => {
            const matchValue_1 = props.UserData.Role;
            let matchResult;
            if (props.AccountSettings.SsoEnabled) {
                switch (matchValue_1) {
                    case "toolManager":
                    case "user": {
                        matchResult = 1;
                        break;
                    }
                    default:
                        matchResult = 0;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0:
                    return singleton({
                        Id: "sso-section",
                        Label: t("settings.sso.sso"),
                    });
                default: {
                    return empty();
                }
            }
        })))))),
        OnSelectAnchor: patternInput_1[1],
        SelectedAnchor: patternInput_1[0],
    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(singleton_1((elems_4 = toList(delay(() => {
        let elems_1, Title;
        return append(singleton(createElement("section", createObj(ofArray([["id", "permissions-section"], (elems_1 = [createElement(SectionContainer, (Title = t("settings.permissions"), {
            Content: createElement(PermissionsView, null),
            Title: unwrap(Title),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
            let elems_2, Title_1;
            return append(singleton(createElement("section", createObj(ofArray([["id", "receivers-section"], (elems_2 = [createElement(SectionContainer, (Title_1 = t("settings.receivers"), {
                Content: createElement(ReceiverSettings, null),
                Title: unwrap(Title_1),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                let elems_3, Title_2;
                const matchValue_4 = props.UserData.Role;
                let matchResult_1;
                if (props.AccountSettings.SsoEnabled) {
                    switch (matchValue_4) {
                        case "toolManager":
                        case "user": {
                            matchResult_1 = 1;
                            break;
                        }
                        default:
                            matchResult_1 = 0;
                    }
                }
                else {
                    matchResult_1 = 1;
                }
                switch (matchResult_1) {
                    case 0:
                        return singleton(createElement("section", createObj(ofArray([["id", "sso-section"], (elems_3 = [createElement(SectionContainer, (Title_2 = t("settings.sso.sso"), {
                            Content: createElement(SSOSettings, {
                                UserData: props.UserData,
                            }),
                            Title: unwrap(Title_2),
                        }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
                    default: {
                        return empty();
                    }
                }
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_4))]))))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
}

