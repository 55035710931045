import { Record, Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, list_type, option_type, class_type, string_type, union_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class DateType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Reservation", "Reminder"];
    }
}

export function DateType_$reflection() {
    return union_type("Shared.Calendar.DateType", [], DateType, () => [[], []]);
}

export class CalendarEntryDto extends Record {
    constructor(Id, StartDate, EndDate, DateType, Title, OnClickRoute) {
        super();
        this.Id = Id;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.DateType = DateType;
        this.Title = Title;
        this.OnClickRoute = OnClickRoute;
    }
}

export function CalendarEntryDto_$reflection() {
    return record_type("Shared.Calendar.CalendarEntryDto", [], CalendarEntryDto, () => [["Id", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", option_type(class_type("System.DateTime"))], ["DateType", DateType_$reflection()], ["Title", string_type], ["OnClickRoute", list_type(string_type)]]);
}

